import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { HotelSearchForm } from '@/components/search';
import { Footer } from "@/components/footer";
import { Header } from "@/components/header";
import HotelCard from '@/components/card';
import { Row, Col, Badge } from 'antd';
import { StyleListingWrapper } from "@/utils/styles";
import { colors } from '@/utils/colors';
import { Placeholder } from '@/components/placeholder';
import animationData from '@/statics/images/animation.json';
import { ModalHotelPhotos } from '@/components/ModalHotelPhotos';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const Favourites = ({ 
    isHomePage, 
    backgroundImage, 
    title, 
    setTitle,
    description, 
    setDistType, 
    distType, 
    form, 
    handleSelectDestination
}) => {

    const [favourites, setFavorites] = useState([]);
    const [_hotel, setIsHotelId] = useState('');
    const [isOpenModalPhotos, setIsOpenModalPhotos] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [urlHotel, setUrlHotel] = useState('');
    const [modalHotelStars, setmodalHotelStars] = useState('');

    useEffect(() => {
        const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
        setFavorites(storedFavorites);

        const destinationDiv = document.getElementById('roomseaker');
		const destinationTop = destinationDiv.offsetTop;
		window.scrollTo({ top: destinationTop - 20, behavior: 'smooth' });

    }, []);
    
    return (
        <>
            <Header
                isHomePage={isHomePage}
                srcBg={backgroundImage}
                title={title}
                description={description}
            />
            <HotelSearchForm 
                isHomePage={isHomePage}
                handleSelectDestination={handleSelectDestination}
                form={form}
                setTitle={setTitle}
                setDistType={setDistType}
                distType={distType}
            />
            
            {favourites?.length > 0 && (
                <StyleListingWrapper>
                    <div className="container">
                        <Row 
                            align="bottom"
                            justify="space-between"
                            className="header"
                        >
                            <div className="header-title">
                                Vos favoris, <span>{favourites.length || '0'} résultat{favourites.length > 1 && ('s')}</span>
                            </div>
                        </Row>
                        <Row gutter={[16, 16]} id="list-hotels-show">
                            {favourites && favourites.map(favourite => (
                                <Col key={`global-${favourite.data.hotel_id}`} xs={24} sm={12} md={8}>
                                    {favourite.data.ribbon_text ? (
                                        <Badge.Ribbon placement="start" text={favourite.data.ribbon_text} color={colors.ribbon}>
                                            <HotelCard
                                                key={favourite.data.hotel_id} 
                                                data={favourite.data} 
                                                urlRedirection={favourite.urlRedirection}
                                                setUrlHotel={setUrlHotel}
                                                setHotelId={setIsHotelId}
                                                setmodalHotelStars={setmodalHotelStars}
                                                setTitleModal={setTitleModal}
                                                setIsOpenModalPhotos={setIsOpenModalPhotos}
                                                isMap={false}
                                                price={{
                                                    grossAmount: favourite.data.composite_price_breakdown?.gross_amount?.value,
                                                    grossAmountNight: favourite.data.composite_price_breakdown?.gross_amount_per_night?.value,
                                                    strikethroughAmount: favourite.data.composite_price_breakdown?.strikethrough_amount?.value,
                                                    strikethroughAmountNight: favourite.data.composite_price_breakdown?.strikethrough_amount_per_night?.value,
                                                    currency: favourite.data.composite_price_breakdown?.gross_amount_per_night?.currency,        
                                                }}
                                            />
                                        </Badge.Ribbon>
                                    ) : (
                                        <HotelCard
                                            key={favourite.data.hotel_id} 
                                            data={favourite.data} 
                                            urlRedirection={favourite.urlRedirection}
                                            setUrlHotel={setUrlHotel}
                                            setHotelId={setIsHotelId}
                                            setmodalHotelStars={setmodalHotelStars}
                                            setTitleModal={setTitleModal}
                                            setIsOpenModalPhotos={setIsOpenModalPhotos}
                                            isMap={false}
                                            price={{
                                                grossAmount: favourite.data.composite_price_breakdown?.gross_amount?.value,
                                                grossAmountNight: favourite.data.composite_price_breakdown?.gross_amount_per_night?.value,
                                                strikethroughAmount: favourite.data.composite_price_breakdown?.strikethrough_amount?.value,
                                                strikethroughAmountNight: favourite.data.composite_price_breakdown?.strikethrough_amount_per_night?.value,
                                                currency: favourite.data.composite_price_breakdown?.gross_amount_per_night?.currency,        
                                            }}
                                        />
                                    )}
                                </Col>
                            ))}
                        </Row>
                    </div>
                </StyleListingWrapper>
            )}
            {favourites?.length === 0 && (
                <Placeholder 
                    lottie={
                        <Lottie
                            options={defaultOptions}
                            height={150}
                            width={150}
                        />
                    }
                    title="Vous n'avez aucun favoris"
                    description="Entrez vos critères de recherche pour découvrir les meilleures offres d'hôtels disponibles."
                />
            )}

            <ModalHotelPhotos
                isOpen={isOpenModalPhotos}
                title={titleModal}
                hotelStars={modalHotelStars}
                _hotel={_hotel}
                href={urlHotel}
                closeModal={() => setIsOpenModalPhotos(false)}
            />

            <Footer
                onClick={(data) => handleSelectDestination(data)}
            />

        </>
    );
};

