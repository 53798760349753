import { rgba } from 'polished';
import styled, { keyframes } from 'styled-components';
import { colors } from '@/utils/colors';
import { Card, Row, Affix } from 'antd';
import bg from "@/statics/images/bg-homepage.jpg";

const MAX_SIZE_CONTAINER = 1024;
const mobile = '@media (max-width: 575px)';

export const StyledWrapperForm = styled.div`
    padding: 20px;
    max-width: ${MAX_SIZE_CONTAINER}px;
    width: 100%;
    background-color: ${colors.white};
    box-shadow: 0 0 10px ${rgba(colors.black, .2)};
    border-radius: 4px;
    margin: -50px auto 50px;
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .user-selector{
        .ant-dropdown-menu-item{
            cursor: default;
            &:hover{
                background-color: transparent;
            }
        }
    }
    form{
        >.ant-form-item{
            margin-inline-end: 0;
        }
    }
    .ant-form-inline{
        margin-bottom: 0px;
        .ant-form-item-with-help{
            margin-bottom: 0;
        }
        .label{
            font-size: 14px;
            font-weight: bold;
        }
        align-items: flex-end;
    }
`;

export const StyleListingWrapper = styled.div`
    padding: 0 20px 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .ant-ribbon-wrapper{
        height: 100%;
    }
    .header{
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid ${rgba(colors.black, .1)};
        padding-top: 20px;
        &.mobile{
            position: sticky;
            top: 0px;
            left: 0;
            z-index: 9;
            background-color: ${colors.bg.global};
        }    
        &-title{
            font-size: 24px;
            font-weight: bold;
            text-transform: capitalize;
            font-family: 'Josefin Sans', sans-serif;
            span{
                font-size: 16px;
                color: ${rgba(colors.black, .6)};
                text-transform: initial;
            }
        }
        &-wrapper{
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            .ant-form-item{
                margin-bottom: 0;
            }
        }
    }
    .container{
        max-width: ${MAX_SIZE_CONTAINER}px;
        width: 100%;
        margin: 0 auto;   
        &-slider{
            background-color: ${colors.white};
            border-radius: 10px;
            //box-shadow: 0 0 10px ${rgba(colors.black, .2)};
            border: 1px solid ${rgba(colors.black, .15)};
            padding: 20px;
        } 
    }
`;

export const StyledCard = styled(Card)`
    transition: all 250ms ease;
    height: 100%;
    display: flex;
    flex-direction: column;
    .asterix{
        position: absolute;
        bottom: 5px;
        right: 25px;
    }
    &:hover{
        box-shadow: 0 0 10px ${rgba(colors.black, .1)};
        transform: scale(1.01);
    }
    .ant-card-body{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        &:before,
        &:after{
            content: unset;
        }
    }
    .cover-wrapper{
        position: relative;
        height: 250px;
        max-width: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .type,
        .fav,
        .photos{
            position: absolute;
            bottom: 10px;
            background-color: ${rgba(colors.black, .4)};
            color: ${colors.white};
            padding: 5px;
            font-weight: bold;
            border-radius: 6px;
            display: flex;
            align-items: center;
        }
        .content-action{
            position: absolute;
            cursor: pointer;
            right: 10px;
            bottom: auto;
            top: 10px;
            display: flex;
            .item{
                background-color: ${rgba(colors.black, .4)};
                color: ${colors.white};
                padding: 5px;
                font-weight: bold;
                border-radius: 6px;
                display: flex;
                align-items: center;
                transition: all 250ms ease;
                margin-right: 10px;
                &:last-child{
                    margin-right: 0;
                }
                &.active{
                    color: ${colors.ribbon};
                }
                &:hover{
                    background-color: ${rgba(colors.black, .2)};
                }
            }
        }
        .type{
            left: 10px;
        }
        .photos{
            right: 10px;
            cursor: pointer;
            transition: all 250ms ease;
            &:hover{
                background-color: ${rgba(colors.black, .2)};
            }
        }
    }
    .review{
        display: flex;
        flex-direction: row;
        &--title{
            font-weight: bold;
        }
        &--notation{
            margin-left: 3px;
            span{
                font-size: 12px;
                color: ${rgba(colors.black, .6)};
            }
        }
    }
    .line-price{
        margin-top: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        .price{
            display: flex;
            flex-direction: column;
            .strikethrough{
                color: ${colors.ribbon};
            }
            .pricing{
                font-weight: bold;
                font-size: 16px;
                span{
                    font-size: 12px;
                    font-weight: normal;
                    color: ${rgba(colors.black, .6)};
                }
            }
        }
    }
    .city{
        .city{
            text-transform: capitalize;
        }
    }
    .star{
        &-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center; 
        }
        margin-left: 5px;
        color: ${colors.gold};
        font-size: 14px;
        background-color: ${rgba(colors.gold, .2)};
        padding: 0px 5px;
        border-radius: 6px;
        display: inline-block;
        span{
            margin-left: 5px;
        }
    }
    ul{
        li{
            align-items: center;
            list-style: none;
            padding: 10px 0;
            border-bottom: 1px solid ${rgba(colors.black, .1)};
            .line-icon{
                display: flex;
                align-items: center;
                svg{
                    margin-right: 5px;
                }
            }
            &:last-child{
                border-bottom: none;
            }
        }
    }
    .meta{
        &-head{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        color: ${colors.black};
        .title{
            font-size: 24px;
            font-weight: bold;
            line-height: 1.5;
            display: flex;
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
`;

export const StyledFakeInput = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6.5px 11px 6.5px;
    border-radius: 6px;
    line-height: 1;
    min-height: 40px;
    margin-right: 16px;
    border: 1px solid #d9d9d9;
    transition: border 0.2s,box-shadow 0.2s;
    font-size: 16px;
    min-width: 282px;
    ${mobile}{
        margin-bottom: 24px;
        margin-right: 0;
    }
    &:hover{
        border-color: ${colors.primaryLight};
    }
    .fake-value{
        margin-left: 5px;
    }
`;

export const StyledDropdownItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    font-size: 16px;
    .label{
        font-weight: bold;
        font-size: 14px;
    }
    .ant-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${colors.primaryLight};
        border-radius: 0px!important;
        border: none!important;
        background-color: transparent;
        box-shadow: none;
        &[disabled]{
            color: ${rgba(colors.black, .2)};
        }
        &:hover{
            background-color: ${rgba(colors.primaryLight, .1)};
            &[disabled]{
                background-color: transparent;
                cursor: not-allowed;
            }
        }
    }
    .value{
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .input{
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid #D9D9D9;
        border-radius: 8px;
    }
    .list-select{
        margin-top: 5px;
    }
`;

export const StyledSkeleton = styled.div`
    background-color: ${colors.white};
    border: 1px solid #f0f0f0;
    width: 100%;
    height: 100%;
    .ant-skeleton{
        width: 100%!important;
        &-image{
            width: 100%!important;
            height: 250px!important;
        }
        &-content{
            padding:24px;
        }
    }
`;

export const StyledPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    margin: 100px auto;
    .image{
        max-width: 350px;
        margin-bottom: 20px;
        img{
            max-width: 100%;
        }
    }
    .text-content{
        text-align: center;
        color: ${colors.black};
        .title{
            font-size: 24px;
            font-weight: bold;
        }
        .description{
            font-size: 16px;
        }
    }
`;

export const StyledOptions = styled(Row)`
    .icon{
        font-size: 24px;
        margin-right: 10px;
    }
`;

export const StyledSmallCard = styled.div`
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 2px ${rgba(colors.black, .2)};
    height: 330px;
    margin-right: 20px;
    position: relative;
    .btn{
        cursor: pointer;
        margin-top: 10px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        align-self: flex-end;
        background-color: ${rgba(colors.black, .1)};
        padding: 5px 10px;
        transition: all 250ms ease;
        &:hover{
            background-color: ${rgba(colors.black, .2)};
            span{
                padding-right: 10px;
            }
        }
        span{
            transition: all 250ms ease;
            padding-right: 5px;
        }
    }
    &:hover{
        .img{
            transform: scale(1.04);
        }
    }
    .text-wrapper,
    .img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .text-wrapper{
        display: flex;
        justify-content: flex-end;
        padding: 0 10px 10px;
        align-items: end;
        h4{
            font-family: 'Josefin Sans', sans-serif;
        }
    }
    .text-content{
        display: flex;
        flex-direction: column;
        background-color: ${colors.white};
        border-radius: 10px;
        padding: 10px;
        width: 100%;
    }
    .img{
        background-size: cover;
        background-position: top center;
        transition: all 250ms ease;
    }
`;


const appear = keyframes`
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const StyledLoaderApp = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${rgba(colors.white, .93)};
    //background-color: rgba(10,17,33, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.white};
    z-index: 999;
    flex-direction: column;
    text-align: center;
    transition: opacity 200ms ease;
    opacity: 0;
    transform: translateY(-100%);
    >div,
    .text{
        transition: all 250ms ease;
        opacity: 0;
        transform: translateY(50px);
    }
    &.active{
        opacity: 1;
        transform: translateY(0);
        >div,
        .text{
            animation-name: ${appear};
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
        }
        >div{
            animation-delay: 0.3s;
        }
        .text{
            animation-delay: 0.5s;
        }
    }
`;

export const StyledNewsletter = styled.div`
    border-radius: 10px;
    padding: 20px;
    background-color: ${rgba(colors.primaryLight, 0.1)};
    border: 1px solid ${colors.primaryLight};
    ${mobile}{
        .a-m-t{
            margin-top: 20px;
        }
    }
    .ant-form-item{
        margin-bottom: 0;
        &-explain-error{
            position: absolute;
            top: -25px;
            left: 0;
            white-space: nowrap;
        }
    }
`;

export const StyledRassurance = styled.div`
    border-radius: 10px;
    padding: 20px 20px 0;
    background-color: ${colors.dark};
    border: 1px solid ${colors.dark};
    .ant-typography{
        color: ${colors.white};
    }
    .btn-wrapper{
        margin-top: 50px;
    }
    .img-wrapper{
        margin: 0 auto;
        img{
            margin: 0 auto;
            width: 100%;
            display: block;
            max-width: 400px;
        }
    }
    ${mobile}{
        .img-wrapper{
            margin: 0 auto;
            max-width: 300px;
        }
        .a-m-t{
            margin-top: 20px;
        }
    }
`;

export const StyledArguments = styled.div`
    border-radius: 10px;
    padding: 20px 20px;
    background-color: ${colors.white};
    border: 1px solid ${rgba(colors.dark, .15)};
    .icon-wrapper{
        display: flex;
        align-items: center;
        margin-right: 10px;
        font-size: 24px;
    }
    ${mobile}{
        .item{
            margin-top: 20px;
        }
    }
`;

export const StyledGalleryPhotos = styled.div`
    position: relative;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    min-height: 0;
    .gallery-nav{
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        z-index: 2;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.right{
            right: 10px;
            left: auto;
        }
        svg{
            margin: 0;
        }
    }
    .image-gallery{
        min-height: 0;
        height: 100%;
        width: 100%;
        &-slide{
            height: 100%;
        }
        &-slides{
            height: 100%;
            ${mobile}{
                height: initial;
            }
            img{
                max-height: 100%!important;
            }
        }
        &-thumbnail{
            &.active,
            &:hover{
                border-color: ${colors.primaryLight};
            }
            width: 70px;
            height: 50px;
            &-inner{
                height: 100%;
            }
            &-image{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &-swipe{
            display: flex;
            height: 100%;
            min-height: 0;
            align-items: center;
        }
        &-slide{
            &-wrapper{
                display: flex;
                min-height: 0;
                flex: 1;
                flex-shrink: 0;
                align-items: center;
                justify-content: center;
            }
            &-content{
                display: flex;
                min-height: 0;
            }
        }
        &-content{
            height: 100%;
            width: 100%;
            min-height: 0;
            display: flex;
            flex-direction: column;
        }
    }
    .container{
        display: flex;
        width: 100%;
        height: calc(80vh - 80px);
        flex-shrink: 0;
        margin-bottom: 10px;
        .ant-skeleton{
            width: 100%!important;
            height: 100%!important;
            &-image{
                width: 100%!important;
                height: 100%!important;
            }
        }
    }
    .thumbnail-skeleton{
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        .ant-skeleton{
            margin-right: 10px;
            &-image{
                width: 70px;
                height: 50px;
                svg{
                    height: 24px!important;
                    width: 24px!important;
                }
            }
        }

    }
`;


export const StyledFooter = styled.div`
    margin-top: 100px;
    background-color: #0C1014;
    padding: 50px 0;
    ${mobile}{
        padding: 50px;
        .a-m-t{
            margin-top: 20px;
        }
    }
    .container{
        max-width: ${MAX_SIZE_CONTAINER}px;
        width: 100%;
        margin: 0 auto;   
    }
    .footer-container{
        display: flex;
        flex-direction: column;
        color: ${colors.white};
        h5{
            margin: 0 0 10px 0;
            color: ${colors.white}!important;
        }
    }
    .item{
        display: flex;
        cursor: pointer;
        transition: all 250ms ease;
        padding: 5px;
        align-self: flex-start;
        font-size: 16px;
        color: ${rgba(colors.white, .6)};
        position: relative;
        &:after{
            border-radius: 4px;
            transition: all 250ms ease;
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: 0;
            height: 100%;
        }
        &:hover{
            &:after{
                width: 100%;
                background-color: ${rgba(colors.white, .2)};
            }
            color: ${colors.white};
        }
    }

`;

export const StyledAffix = styled(Affix)`
    .ant-affix{
        .form-wrapper{
            margin: 0 auto;
        }
    }
`;

export const StyledContainer = styled.div`
    padding: 0 20px;
`;


export const StyledApp = styled.div`
    background-image: url('${props => props.bg || bg}');
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    color: ${colors.white};
    position: relative;
    .overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, ${rgba(colors.primaryDark, .3)} 0%, ${rgba(colors.primaryDark, .7)} 100%);
    }
    .app-header{
        max-width: 1024px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }
    .logo{
        font-family: 'Josefin Slab', sans-serif;
        color: ${colors.white};
        max-width: 100px;
        img{
            display: block;
            max-width: 100%;
        }
    }
    h1,
    h2{
        font-family: 'Josefin Sans', sans-serif;
    }
    h1{
        font-size: 54px;
        margin-bottom: 20px;
    }
    h2{
        font-size: 24px;
        font-weight: 400;
    }
    .text-wrapper{
        margin: 100px 0;
        max-width: 600px;
        display: flex;
        flex-direction: column;
    }
    nav{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .menu{
            ul{
                display: flex;
                flex-direction: row;
                align-items: center;
                li{
                    list-style: none;
                    display: flex;
                    margin-right: 20px;
                    flex-direction: row;
                    align-items: center;
                    .icon{
                        display: flex;
                        align-items: center;
                        margin-left: 10px;
                        font-size: 12px;
                        font-weight: bold;
                        border-radius: 4px;
                        padding: 5px;
                        color: ${colors.ribbon};
                        background-color: ${rgba(colors.ribbon, .15)};
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    a{
                        transition: all 250ms ease;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-size: 16px;
                        position: relative;
                        padding: 5px 10px;
                        span{
                            color: ${rgba(colors.white, .6)};
                        }
                        &:after{
                            border-radius: 4px;
                            transition: all 250ms ease;
                            position: absolute;
                            top: 0;
                            left: 0;
                            content: "";
                            width: 0;
                            height: 100%;
                        }
                        &:hover{
                            &:after{
                                width: 100%;
                                background-color: ${rgba(colors.white, .2)};
                            }
                            span{
                                color: ${colors.white};
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const StyledPopup = styled(Row)`
    width: 100%;
    >.ant-space{
        width: 100%;
    }
    .ant-space{
        &-item{
            &:last-child{
                width: 100%;
            }
        }
    }
    h5.ant-typography{
        margin: 0;
    }
    .star{
        color: ${colors.gold};
    }
    .line-price{
        margin-top: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        .price{
            display: flex;
            flex-direction: column;
            .strikethrough{
                color: ${colors.ribbon};
            }
            .pricing{
                font-weight: bold;
                font-size: 16px;
                span{
                    font-size: 12px;
                    font-weight: normal;
                    color: ${rgba(colors.black, .6)};
                }
            }
        }
    }
    .review{
        display: flex;
        flex-direction: row;
        &--title{
            font-weight: bold;
        }
        &--notation{
            margin-left: 3px;
            span{
                font-size: 12px;
                color: ${rgba(colors.black, .6)};
            }
        }
    }
`;

export const StyledWrapper = styled.div`
    border: 1px solid ${rgba(colors.black, .1)};
    border-radius: 4px;
    padding: 10px 5px;
    margin-bottom: 8px;
    h5{
        margin-left: 5px;
    }
`;

export const StyledLabel = styled.div`
    display: flex;
    width: 100%;
    >label{
        position: relative;
        width: 100%;
        display: flex;
        padding: 2px 32px 2px 5px;
        &:hover{
            cursor: pointer;
            border-radius: 4px;
            background-color: ${rgba(colors.black, .05)};
        }
        label{
            margin-right: 10px;
        }
        .count{
            font-size: 11px;
            position: absolute;
            top: 4px;
            right: 2px;
        }
    }
`;

export const StyledBottomSheet = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    visibility: hidden;
    z-index: 999999999;
    .title{
        font-size: 16px;
        font-weight: bold;
    }
    .close{
        font-size: 32px;
    }
    &.active{
        visibility: visible;
        .bottom-sheet{
            &--overlay{
                opacity: 1;
            }
            &--wrapper{
                transform: translateY(0);
            }
        }
    }
    .bottom-sheet{
        &--line-header{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
        }
        
        &--overlay{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: ${rgba(colors.black, .9)};
            opacity: 0;
            transition: all 250ms ease;
        }
        &--wrapper{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            border-radius: 30px 30px 0 0;
            background-color: ${colors.white};
            padding: 20px;
            transform: translateY(100%);
            transition: all 250ms ease;
        }
    }

`;
