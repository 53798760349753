import React, { useState, useEffect, useCallback, useMemo, useContext } from "react";
import {
    Row,
    Button,
    Typography,
    Alert,
    Tooltip,
    notification
} from "antd";
import classNames from 'classnames';
import { FaSwimmingPool, FaMobileAlt } from 'react-icons/fa';
import { BiPhotoAlbum, BiMapPin } from 'react-icons/bi';
import { BsBalloonHeartFill } from "react-icons/bs";
import {
    StarFilled,
    
} from '@ant-design/icons';
import { StyledCard } from "@/utils/styles";
import { colors } from '@/utils/colors';
import { AppContext } from '@/AppContext';

const { Text } = Typography;

const HotelCard = React.memo(({ 
    data,
    price,
    params, 
    setHotelId, 
    setTitleModal, 
    setIsOpenModalPhotos, 
    setUrlHotel, 
    setmodalHotelStars, 
    urlRedirection,
    countDays,
    setIsOpenModalMap,
    setLocalisationMapUnicast,
    setOpenModalHotelMap,
    isMap
}) => {

    const { exchangeRatesCache, setCountFav } = useContext(AppContext);
    const { grossAmount, grossAmountNight, strikethroughAmount, strikethroughAmountNight, currency } = price;

    const convertAmount = useCallback((amount, currency) => {
        if (currency === "EUR") {
            return amount?.toFixed(2);
        } else if (exchangeRatesCache) {
            const rate = exchangeRatesCache.exchange_rates?.find(
                (rate) => rate.currency === currency
            );
            if (rate) {
                return (amount / parseFloat(rate.exchange_rate_buy))?.toFixed(2);
            } else {
                return amount?.toFixed(2);
            }
        }
        return null;
    }, [exchangeRatesCache]);
    
    const [isFavorite, setIsFavorite] = useState(false);
    const favoritesFromStorage = useMemo(() => {
        return JSON.parse(localStorage.getItem('favorites')) || [];
    }, []);
    const [favorites, setFavorites] = useState(favoritesFromStorage);

    const toggleFavorite = useCallback(() => {
        const favoritesFromStorage = JSON.parse(localStorage.getItem('favorites')) || [];
        const index = favoritesFromStorage.findIndex(fav => fav.id === data.hotel_id);
        let newFavorites;
        if (index > -1) {
            newFavorites = favoritesFromStorage.filter(fav => fav.id !== data.hotel_id);
            notification.success({
                message: `${data.hotel_name}`,
                description: 'A été retiré de vos favoris.',
                placement: 'bottomLeft',
                icon: <BsBalloonHeartFill color={colors.ribbon} />
            });

        } else {
            newFavorites = [...favoritesFromStorage, { id: data.hotel_id, urlRedirection: `${data.url}?aid=7992361&${params}`, data: data }];
            notification.success({
                message: `${data.hotel_name}`,
                description: 'A été ajouté à vos favoris.',
                placement: 'bottomLeft',
                icon: <BsBalloonHeartFill color={colors.ribbon} />
            });

        }
        localStorage.setItem('favorites', JSON.stringify(newFavorites));
        setFavorites(newFavorites);
        setCountFav(newFavorites.length);
        setIsFavorite(newFavorites.some(fav => fav.id === data.hotel_id));
    }, [data, params, setCountFav]);
    
    useEffect(() => {
        const favoritesFromStorage = JSON.parse(localStorage.getItem('favorites')) || [];
        setFavorites(favoritesFromStorage);
    }, []);
    
    useEffect(() => {
        setIsFavorite(favorites.some(fav => fav.id === data.hotel_id));
    }, [favorites, data.hotel_id]);
    
    const setModal = useCallback((_id, title) => {
        setHotelId(_id);
        setmodalHotelStars(data.class || 0);
        setUrlHotel(`${data.url}?aid=7992361&${params}`);
        setTitleModal(title);
        setIsOpenModalPhotos(true);
    }, [data.class, data.url, params, setHotelId, setIsOpenModalPhotos, setTitleModal, setUrlHotel, setmodalHotelStars]);

    const setModalMap = useCallback((hotel) => {
        if(isMap){
            setUrlHotel(`${data.url}?aid=7992361&${params}`);
            setLocalisationMapUnicast({ lat: hotel?.latitude, lon: hotel?.longitude });
            setTitleModal(hotel?.hotel_name);
            setOpenModalHotelMap(hotel);
            setIsOpenModalMap(true);
        }
    }, [data, params, setIsOpenModalMap, setTitleModal, setLocalisationMapUnicast, setUrlHotel, setOpenModalHotelMap, isMap]);

    return (
        <StyledCard
            cover={
                <div className="cover-wrapper">
                    <div className="content-action">
                        {isMap && (
                            <div 
                                className="item"
                                onClick={() => setModalMap(data)}
                            >
                                <BiMapPin />
                            </div>
                        )}
                        <div 
                            className={classNames('item', { active: isFavorite })}
                            onClick={toggleFavorite}
                        >
                            <BsBalloonHeartFill />
                        </div>
                    </div>
                    <img
                        alt={data.hotel_name}
                        src={data.max_1440_photo_url}
                    />
                    <div className="type">
                        {data.accommodation_type_name}
                    </div>
                    <div className="photos" onClick={() => setModal(data.hotel_id, data.hotel_name)}>
                        <BiPhotoAlbum /> Plus de photos
                    </div>
                </div>
            }
        >
            <div className="meta">
                <div className="meta-head">
                    <div className="title">
                        <Typography.Title style={{marginBottom: 0}} level={4} ellipsis>{data.hotel_name}</Typography.Title>
                    </div>
                </div>
                <ul>
                    <li>
                        <div className="review">
                            <div className="review--title">{data.review_score_word || 'Aucun avis'}</div>
                            {data.review_score_word && (
                                <div className="review--notation">
                                    - <b>{data.review_score}</b>/10 <span>({data.review_nr && data.review_nr} avis)</span>
                                </div>
                            )}
                        </div>
                    </li>
                    {data.class > 0 && (
                        <li className="star-wrapper">
                            {data.accommodation_type_name}
                            <div className="star">
                                {data.class}
                                <StarFilled />
                            </div>
                        </li>
                    )}
                    {data.distance_to_cc && data.distance_to_cc > 0 && (
                        <li>
                            <span className="city">{data.city}</span>, à {data.distance_to_cc < 1 ? data.distance_to_cc * 1000 : data.distance_to_cc}{data.distance_to_cc < 1 ? 'm' : 'km'} du centre ville.
                        </li>
                    )}
                    {data.distance_to_cc && data.distance_to_cc === 0 && (
                        <li>
                            <span className="city">Cet hôtel se trouve en hypercentre.</span>
                        </li>
                    )}
                    {data.has_swimming_pool && (
                        <li>
                            <div className="line-icon"><FaSwimmingPool /> Avec piscine</div>
                        </li>
                    )}
                    {data.is_mobile_deal === 1 && (
                        <li>
                            <div className="line-icon"><FaMobileAlt /> Tarif réservé aux mobiles</div>
                        </li>
                    )}
                </ul>

                {data.urgency_message && (
                    <Alert message={data.urgency_message} type="error" />
                )}

            </div>
            {data.soldout !== 1 && (
                <Row 
                    align="middle" 
                    justify="space-between" 
                    className="line-price"
                >
                    {countDays && countDays > 1 ? (
                        <div className="price">
                            {strikethroughAmount && strikethroughAmount > 0 && (
                                <span className="strikethrough"><strike>{Math.round(convertAmount(strikethroughAmount, currency))}€</strike></span>
                            )}
                            <Tooltip title={`${Math.round(convertAmount(grossAmountNight, currency))}€ /nuit`}>
                                <span className="pricing">
                                    <span>{countDays} nuits : </span>{Math.round(convertAmount(grossAmount, currency))}€
                                </span>
                            </Tooltip>
                        </div>
                    ) : (
                        <div className="price">
                            {strikethroughAmount && strikethroughAmount > 0 && (
                                <span className="strikethrough"><strike>{Math.round(convertAmount(strikethroughAmountNight, currency))}€</strike></span>
                            )}
                            <span className="pricing">
                                {Math.round(convertAmount(grossAmountNight, currency))}€<span>/nuit</span>
                            </span>
                        </div>
                    )}

                    <Button 
                        target="_blank"
                        href={urlRedirection ? urlRedirection : `${data.url}?aid=7992361&${params}`}
                        size="large"
                        type="primary"
                    >
                        Voir l'offre
                    </Button>
                </Row>
            )}
            {data.soldout === 1 && (
                <Row 
                    align="middle" 
                    justify="space-between" 
                    className="line-price"
                >
                    <Text type="danger">Produit épuisé</Text>
                </Row>
            )}

        </StyledCard>
    )
});

export default HotelCard;
