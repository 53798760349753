export const colors = {
    primaryDark: '#2D3848',
    //primaryLight: '#4BA3C7',
    primaryLight: '#02A4FF',
    greyLight: '#DADADA',
    greyDark: '#707070',
    white: '#FFF',
    black: '#000',
    ribbon: '#EC144D',
    gold: '#ffb700',
    dark: '#272727',
    bg: {
        global: '#F5F5F5'
    }
};
