import React, { useEffect } from 'react';
import { HotelSearchForm } from '@/components/search';
import { Footer } from "@/components/footer";
import { Header } from "@/components/header";
import { Typography } from 'antd';
import { StyledContainer } from '@/utils/styles';

const { Title, Paragraph } = Typography;

export const Mentions = ({ 
    isHomePage, 
    backgroundImage, 
    title, 
    setTitle,
    description, 
    setDistType, 
    distType, 
    form, 
    handleSelectDestination,
    countFav
}) => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    
    return (
        <>
            <Header
                isHomePage={isHomePage}
                srcBg={backgroundImage}
                title={title}
                description={description}
                countFav={countFav}
            />
            <HotelSearchForm 
                isHomePage={isHomePage}
                handleSelectDestination={handleSelectDestination}
                form={form}
                setTitle={setTitle}
                setDistType={setDistType}
                distType={distType}
            />
            
            <StyledContainer className="container">
                <div style={{ maxWidth: 800, margin: '0 auto' }}>
                    <Title style={{ textAlign: 'center', marginBottom: 50 }}>Mentions légales</Title>
                    <Paragraph>
                        Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance
                        dans l'économie numérique, dite L.C.E.N., nous portons à la connaissance des utilisateurs et visiteurs du site :
                    </Paragraph>
                    <Paragraph>
                        <strong>Editeur du site :</strong> 
                        <br />
                        stephane@stuandco.com
                    </Paragraph>
                    <Paragraph>
                        <strong>Hébergeur :</strong> OVH
                        <br />
                        2 rue Kellermann - 59100 Roubaix - France
                    </Paragraph>
                    <Paragraph>
                        <strong>Conditions d'utilisation :</strong> L'utilisation du présent site implique l'acceptation pleine et
                        entière des conditions générales d'utilisation décrites ci-après. Ces conditions d'utilisation sont susceptibles
                        d'être modifiées ou complétées à tout moment, les utilisateurs du site sont donc invités à les consulter de
                        manière régulière.
                    </Paragraph>
                    <Paragraph>
                        <strong>Propriété intellectuelle :</strong> Tous les éléments du présent site, notamment les textes, images,
                        graphismes, logos, vidéos, icônes et sons, ainsi que leur mise en forme sont la propriété exclusive de
                        l'éditeur, à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs.
                        Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces
                        différents éléments est strictement interdite sans l'accord express par écrit de l'éditeur.
                    </Paragraph>
                    <Paragraph>
                        <strong>Données personnelles :</strong> Les données collectées sur ce site sont uniquement destinées à un usage
                        interne. En aucun cas ces données ne seront cédées ou vendues à des tiers. Conformément aux articles 38 et suivants
                        de la loi 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, vous disposez d'un
                        droit d'accès, de rectification, de suppression et d'opposition aux données vous concernant. Pour l'exercer,
                        adressez-vous à l'éditeur du site.
                    </Paragraph>
                </div>

            </StyledContainer>
            <Footer
                onClick={(data) => handleSelectDestination(data)}
            />

        </>
    );
};

