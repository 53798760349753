const images = {
    islande: require('@/statics/images/country/islande.jpg').default,
    maldives: require('@/statics/images/country/maldives.jpg').default,
    thailande: require('@/statics/images/country/thailande.jpg').default,
    canada: require('@/statics/images/country/canada.jpg').default,
    japon: require('@/statics/images/country/japon.jpg').default,
    italie: require('@/statics/images/country/italie.jpg').default,
    maroc: require('@/statics/images/country/maroc.jpg').default,
    costaRica: require('@/statics/images/country/costa-rica.jpg').default,
    grece: require('@/statics/images/country/grece.jpg').default,
    tanzanie: require('@/statics/images/country/tanzanie.jpg').default,
    paris: require('@/statics/images/city/paris.jpg').default,
    barcelone: require('@/statics/images/city/barcelone.jpg').default,
    bangkok: require('@/statics/images/city/bangkok.jpg').default,
    dubai: require('@/statics/images/city/dubai.jpg').default,
    marrakech: require('@/statics/images/city/marrakech.jpg').default,
    newYork: require('@/statics/images/city/new-york.jpg').default,
    sydney: require('@/statics/images/city/sydney.jpg').default,
    rioDeJaneiro: require('@/statics/images/city/rio-de-janeiro.jpg').default,
    tokyo: require('@/statics/images/city/tokyo.jpg').default,
    amsterdam: require('@/statics/images/city/amsterdam.jpg').default,
};

export const countryPlaceholder = [
    {
        country: "Islande",
        description: "Les meilleurs hôtels de glace",
        image: images.islande,
        type: 'country',
        footerLabel: "Voyager en Islande"
    },
    {
        country: "Maldives",
        description: "Les villas sur pilotis les plus luxueuses",
        image: images.maldives,
        type: 'country',
        footerLabel: "Voyager au Maldives"
    },
    {
        country: "Thaïlande",
        description: "Les bungalows en bord de mer les plus pittoresques",
        image: images.thailande,
        type: 'country',
        footerLabel: "Voyager en Thaïlande"
    },
    {
        country: "Canada",
        description: "Les chalets les plus confortables en pleine nature",
        image: images.canada,
        type: 'country',
        footerLabel: "Voyager au Canada"
    },
    {
        country: "Japon",
        description: "Les ryokans traditionnels les plus authentiques",
        image: images.japon,
        type: 'country',
        footerLabel: "Voyager au Japon"
    },
    {
        country: "Italie",
        description: "Les agriturismi les plus charmants",
        image: images.italie,
        type: 'country',
        footerLabel: "Voyager en Italie"
    },
    {
        country: "Maroc",
        description: "Les riads les plus élégants dans les médinas",
        image: images.maroc,
        type: 'country',
        footerLabel: "Voyager au Maroc"
    },
    {
        country: "Costa Rica",
        description: "Les lodges écologiques les plus respectueux de l'environnement",
        image: images.costaRica,
        type: 'country',
        footerLabel: "Voyager au Costa Rica"
    },
    {
        country: "Grèce",
        description: "Les maisons troglodytes les plus atypiques à Santorin",
        image: images.grece,
        type: 'country',
        footerLabel: "Voyager en Grèce"
    },
    {
        country: "Tanzanie",
        description: "Les camps de luxe les plus exclusifs pour observer la faune lors d'un safari",
        image: images.tanzanie,
        type: 'country',
        footerLabel: "Voyager en Tanzanie"
    },
];

export const cityPlaceholder = [
    {
        city: "Paris",
        description: "Les hôtels particuliers les plus luxueux",
        image: images.paris,
        type: 'city',
        footerLabel: "Découvrir Paris"
    },
    {
        city: "Barcelone",
        description: "Les appartements modernistes les plus élégants",
        image: images.barcelone,
        type: 'city',
        footerLabel: "Découvrir Barcelone"
    },
    {
        city: "New-York",
        description: "Les suites penthouse les plus spectaculaires avec vue sur la ville",
        image: images.newYork,
        type: 'city',
        footerLabel: "Découvrir New-York"
    },
    {
        city: "Tokyo",
        description: "Les hôtels capsules les plus high-tech",
        image: images.tokyo,
        type: 'city',
        footerLabel: "Découvrir Tokyo"
    },
    {
        city: "Marrakech",
        description: "Les riads les plus traditionnels dans la médina",
        image: images.marrakech,
        type: 'city',
        footerLabel: "Découvrir Marrakech"
    },
    {
        city: "Amsterdam",
        description: "Les maisons flottantes les plus pittoresques sur les canaux",
        image: images.amsterdam,
        type: 'city',
        footerLabel: "Découvrir Amsterdam"
    },
    {
        city: "Rio de Janeiro",
        description: "Les hôtels en bord de plage les plus luxueux à Copacabana",
        image: images.rioDeJaneiro,
        type: 'city',
        footerLabel: "Découvrir Rio de Janeiro"
    },
    {
        city: "Bangkok",
        description: "Les hôtels de charme dans les quartiers historiques de la ville",
        image: images.bangkok,
        type: 'city',
        footerLabel: "Découvrir Bangkok"
    },
    {
        city: "Dubaï",
        description: "Les hôtels les plus extravagants avec des piscines à débordement et des spas de luxe",
        image: images.dubai,
        type: 'city',
        footerLabel: "Découvrir Dubaï"
    },
    {
        city: "Sydney",
        description: "Les hôtels les plus branchés dans le quartier de Surry Hills",
        image: images.sydney,
        type: 'city',
        footerLabel: "Découvrir Sydney"
    }
];
