import React, { useState, useCallback, useEffect } from 'react';
import { Checkbox, Button, Modal, Typography } from 'antd';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { StyledLabel, StyledWrapper } from "@/utils/styles";
import Masonry from 'react-masonry-css'

const modalBodyStyle = { overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' };
const breakpointColumnsObj = {
	default: 4,
	1100: 3,
	700: 1,
	500: 1
};
const { Title } = Typography;

export const ModalFilters = ({ title, isOpen, filtersList, filtersSelected, setFilters, closeModal, form }) => {
	const [expandedFilterId, setExpandedFilterId] = useState(null);
	const [localFiltersSelected, setLocalFiltersSelected] = useState([...filtersSelected]);
	
	useEffect(() => {
		setLocalFiltersSelected([...filtersSelected]);
	}, [isOpen, filtersSelected]);
	
	const handleCancelModal = useCallback(() => {
		closeModal();
	}, [closeModal]);
	
	const handleCategoryChange = useCallback((categoryId) => {
		const updatedLocalFilters = localFiltersSelected.includes(categoryId)
		? localFiltersSelected.filter((id) => id !== categoryId)
		: [...localFiltersSelected, categoryId];
		
		setLocalFiltersSelected(updatedLocalFilters);
	}, [localFiltersSelected]);
	
	const toggleCategoryExpansion = useCallback((filterId) => {
		setExpandedFilterId((prevId) => (prevId === filterId ? null : filterId));
	}, []);
	
	const clearAllFilters = useCallback(() => {
		setLocalFiltersSelected([]);
	}, []);
	
	const handleValidModal = useCallback(() => {
		setFilters(localFiltersSelected);
		form.submit();
		closeModal();
	}, [closeModal, form, localFiltersSelected, setFilters]);
	
	return (
		<Modal
			centered
			width={'95vh'}
			title={title}
			open={isOpen}
			onOk={handleValidModal}
			onCancel={handleCancelModal}
			bodyStyle={modalBodyStyle}
			footer={[
				<Button key="clear" disabled={localFiltersSelected?.length === 0} onClick={clearAllFilters}>Réinitialiser</Button>,
				<Button key="cancel" onClick={handleCancelModal}>Annuler</Button>,
				<Button key="valid" type="primary" onClick={handleValidModal}>
				Valider
				</Button>
			]}
		>
			<Masonry
				className="my-masonry-grid"
				breakpointCols={breakpointColumnsObj}
				columnClassName="my-masonry-grid_column"
			>
			{filtersList?.map((filter) => (
				<StyledWrapper key={filter?.id}>
					<Title level={5}>{filter?.title}</Title>
					{filter?.categories && filter?.categories
						.sort((a, b) => (a.popular_rank || 0) - (b.popular_rank || 0))
						.slice(0, expandedFilterId === filter.id ? undefined : 10)
						.map((category) => (
							<StyledLabel key={category?.id}>
								<label>
									<Checkbox
									checked={localFiltersSelected?.includes(category.id)}
									onChange={() => handleCategoryChange(category.id)}
									/>
									{category?.name} <span className="count">({category?.count})</span>
								</label>
							</StyledLabel>
						))}
						{filter?.categories && filter?.categories.length > 10 && (
							<div className="btn">
								{expandedFilterId === filter.id ? (
									<Button
										onClick={() => toggleCategoryExpansion(filter.id)}
										type="link"
										icon={<BiChevronUp />}
									>
										Afficher moins
									</Button>
									) : (
									<Button
										onClick={() => toggleCategoryExpansion(filter.id)}
										type="link"
										icon={<BiChevronDown />}
									>
										Tout afficher ({filter?.categories.length - 10})
									</Button>
								)}
							</div>
						)}
					</StyledWrapper>
				))}
			</Masonry>
		</Modal>
	);
};
