import React from 'react';
import { HotelSearchForm } from '@/components/search';
import { Footer } from "@/components/footer";
import { Header } from "@/components/header";

export const HomePage = ({ 
    isHomePage,
    backgroundImage,
    title,
    description,
    setDistType,
    distType,
    form,
    handleSelectDestination,
    isSearchPage,
    setTitle
}) => {

    return (
        <>
            <Header
                isHomePage={isHomePage}
                srcBg={backgroundImage}
                title={title}
                description={description}
            />
            <HotelSearchForm 
                isHomePage={isHomePage}
                handleSelectDestination={handleSelectDestination}
                form={form}
                setTitle={setTitle}
                setDistType={setDistType}
                distType={distType}
                isSearchPage={isSearchPage}
            /> 
            <Footer
                onClick={(data) => handleSelectDestination(data)}
            />
        </>
    );
};

