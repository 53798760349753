import { StyledSmallCard } from "@/utils/styles";
import { Typography } from 'antd';
import { BsArrowRight } from 'react-icons/bs';
const { Title, Text } = Typography;

export const SmallCard = ({ src, title, description, onClick }) => {
    return (
        <StyledSmallCard>
            <div 
                className="img" 
                style={{
                    backgroundImage: `url(${src})`
                }}
            />
            <div className="text-wrapper">
                <div className="text-content">
                    <Title level={4} className="title" style={{marginBottom: 0}}>
                        {title}
                    </Title>
                    <Text className="desc">
                        {description}
                    </Text>
                    <div className="btn" onClick={onClick}>
                        <Text>Découvrir</Text>
                        <BsArrowRight />
                    </div>
                </div>
            </div>
        </StyledSmallCard>
    );
}
