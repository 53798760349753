import React, { createContext, useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import devConfig from '@/config/dev';
import prodConfig from '@/config/prod';

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
	
	const [hotels, setHotels] = useState([]);
	const [countResults, setCountResults] = useState();

	const countFavStorage = JSON.parse(localStorage.getItem('favorites')) || [];
	const [countFav, setCountFav] = useState(countFavStorage.length || '');

	const intervalId = useRef(null);
	const checkLastUpdateDate = localStorage.getItem('exchangeRatesCacheLastUpdateDate') || null;
	const [lastUpdateDate, setLastUpdateDate] = useState(checkLastUpdateDate || null);
	const [exchangeRatesCache, setExchangeRatesCache] = useState(() => {
		const cachedRates = localStorage.getItem('exchangeRatesCache');
		return cachedRates ? JSON.parse(cachedRates) : null;
	});

	const fetchExchangeRates = useCallback(async () => {
		let cachedRates = localStorage.getItem('exchangeRatesCache');
		let lastUpdateDate = localStorage.getItem('exchangeRatesCacheLastUpdateDate');
		let response;
	
		if (cachedRates) {
			response = JSON.parse(cachedRates);
			setExchangeRatesCache(response);
		} 

		if (!lastUpdateDate || dayjs(lastUpdateDate).isBefore(dayjs().subtract(1, 'day'))) {
			try {
				response = await axios.get(`${config.API_URL}/exchange-rate`);
				setExchangeRatesCache(response?.data);
				localStorage.setItem('exchangeRatesCache', JSON.stringify(response?.data));
				localStorage.setItem('exchangeRatesCacheLastUpdateDate', dayjs().toISOString());
				setLastUpdateDate(dayjs().startOf('day'));
				} catch (error) {
					console.error(error);
				}
		}
	}, []);
	
	const updateExchangeRates = useCallback(async() => {
		try {
			const response = await axios.get(`${config.API_URL}/exchange-rate`);
			setExchangeRatesCache(response?.data);
			localStorage.setItem('exchangeRatesCache', JSON.stringify(response?.data));
			localStorage.setItem('exchangeRatesCacheLastUpdateDate', dayjs().toISOString());
			setLastUpdateDate(dayjs().startOf('day'));
		} catch (error) {
			console.error(error);
		}
	}, []);
	
	useEffect(() => {
		if (exchangeRatesCache === null) {
			fetchExchangeRates();
		} else {
			const cachedDate = dayjs(exchangeRatesCache?.base_currency_date);
			const now = dayjs();
			const diff = now.diff(cachedDate, 'day');
			if (diff >= 1 || !now.isSame(cachedDate, 'day')) {
				if (lastUpdateDate === null || !now.isSame(lastUpdateDate, 'day')) { 
					updateExchangeRates();
					setLastUpdateDate(now.startOf('day'));
				}
			} else if (diff !== 0) {
				const tomorrow = dayjs().add(1, 'day').startOf('day');
				const hoursToWait = tomorrow.diff(now, 'hour');
				intervalId.current = setInterval(() => {
					if (lastUpdateDate === null || !now.isSame(lastUpdateDate, 'day')) {
						updateExchangeRates();
						setLastUpdateDate(now.startOf('day'));
					}
				}, hoursToWait * 3600000);
			}
		}
		return () => {
			clearInterval(intervalId.current);
		};
	}, [exchangeRatesCache, lastUpdateDate,fetchExchangeRates, updateExchangeRates]);
	
	return (
		<AppContext.Provider
			value={{
				hotels,
				setHotels,
				countResults,
				setCountResults,
				exchangeRatesCache,
				countFav,
				setCountFav
			}}
		>
			{children}
		</AppContext.Provider>
	);
};
