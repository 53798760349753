import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import {
    Form,
    Input,
    Button,
    DatePicker,
    Row,
    Badge,
    Dropdown,
    Col,
    Typography,
    AutoComplete,
    Radio,
    Select,
    Space,
    Grid,
    notification,
    Empty
} from "antd";
import Slider from "react-slick";
import dayjs from "dayjs";
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import Lottie from 'react-lottie';
import { BiBed, BiX, BiUser, BiPlus, BiMinus, BiMap, BiFilter, BiSortAlt2, BiHotel } from 'react-icons/bi';
import { FaLandmark } from 'react-icons/fa';
import { RiHotelLine } from 'react-icons/ri';
import { TbPlaneTilt } from 'react-icons/tb';
import { countryPlaceholder, cityPlaceholder } from "@/utils/data";
import { colors } from '@/utils/colors';
import { StyledAffix, StyledBottomSheet, StyledWrapperForm, StyledLoaderApp, StyleListingWrapper, StyledDropdownItem, StyledFakeInput, StyledOptions } from "@/utils/styles";
import HotelCard from '@/components/card';
import { SkeletonCard } from '@/components/skeleton';
import { SmallCard } from '@/components/smallcard';
import { Newsletter } from '@/components/newsletter';
import { Rassurance } from '@/components/rassurance';
import { Arguments } from '@/components/arguments';
import { Placeholder } from '@/components/placeholder';
import { ModalHotelPhotos } from '@/components/ModalHotelPhotos';
import { ModalMap } from '@/components/ModalMap';
import { ModalMapUnicast } from '@/components/ModalMapUnicast';
import { ModalFilters } from '@/components/ModalFilters';
import noresult from '@/statics/images/placeholder-empty.png';
import animationData from '@/statics/images/animation.json';
import { AppContext } from '@/AppContext';
import devConfig from '@/config/dev';
import prodConfig from '@/config/prod';

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

const { useBreakpoint } = Grid;
const marginTop = { marginTop: 50 };
const imageHeightEmpty = { height: 60 };

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const { RangePicker } = DatePicker;
const { Paragraph, Title } = Typography;
const THROTTLE_TIME = 1000;

const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    infinite: true,
    swipeToSlide: false,
    speed: 500,
    lazyLoad: 'ondemand',
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const CounterButton = ({ value, onDecrement, onIncrement, isDisabled }) => {
    return (
        <Col justify="end">
            <Col>
                <div className="input">
                    <Button disabled={isDisabled} onClick={onDecrement}><BiMinus /></Button>
                    <div className="value">{value}</div>
                    <Button onClick={onIncrement} disabled={value > 29}><BiPlus /></Button>
                </div>
            </Col>
        </Col>
    );
};

export const HotelSearchForm = ({ 
    isHomePage, 
    handleSelectDestination, 
    form,
    setTitle,
    setDistType, 
    distType,
    isSearchPage
}) => {
    const screens = useBreakpoint();
    const today = dayjs();
    let friday = today.startOf('week').add(5, 'days');
    let sunday = friday.add(2, 'days');
    if (today.day() > 5) { // si aujourd'hui est samedi, dimanche ou plus tard
        friday = friday.add(7, 'days');
        sunday = sunday.add(7, 'days');
    }
    const defaultDates = [friday, sunday];

    // modal filters
    const [filters, setFiltersList] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [modalFilters, setModalFilters] = useState(false);
    // modal photos
    const [_hotel, setIsHotelId] = useState('');
    const [isOpenModalPhotos, setIsOpenModalPhotos] = useState(false);
    const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [urlHotel, setUrlHotel] = useState('');
    const [modalHotelStars, setmodalHotelStars] = useState('');
    const [modalHotelMap, setModalHotelMap] = useState(false);
    const [modalHotelMapUnicast, setOpenModalUnicast] = useState(false);
    const [selectedHotelMapUnicast, setOpenModalHotelMap] = useState('');
    const [localisationMap, setLocalisationMap] = useState('');
    const [localisationMapUnicast, setLocalisationMapUnicast] = useState({});
    const [countDays, setCountDays] = useState('');

    const [isSubmitFromForm, setSubmitFromForm] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [loadingMoreHotels, setLoadingMoreHotels] = useState(false);
    const [lastLoadTime, setLastLoadTime] = useState(Date.now());
    const [pagination, setPagination] = useState(0);
    const [paginationCount, setPaginationCount] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const [value, setValue] = useState('');
    const [distId, setDistId] = useState('');
    //const [hotels, setHotels] = useState([]);
    const { hotels, setHotels } = useContext(AppContext);
    const { countResults, setCountResults } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState('priceasc');
    const history = useHistory();
    const location = useLocation();
    const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [queryParams, setQueryParams] = useState(urlSearchParams);
    const queryParamsRoom = urlSearchParams;
    const [searchData, setSearchData] = useState({});
    const initialValuesRoom = useMemo(() => {
        const initialValuesRoom = JSON.parse(localStorage.getItem("searchFormValues")) || {};
        return {
            adults: parseInt(initialValuesRoom.adults) || parseInt(queryParamsRoom.get('adults')) || 2,
            children: parseInt(initialValuesRoom.children) || parseInt(queryParamsRoom.get('group_children')) || 0,
            room: parseInt(initialValuesRoom.room) || parseInt(queryParamsRoom.get('no_rooms')) || 1,
            distType: initialValuesRoom.distType || queryParamsRoom.get('dest_type') || null
        };
    }, [queryParamsRoom]);

    const [infosState, setInfosState] = useState({
        adults: initialValuesRoom.adults,
        children: initialValuesRoom.children,
        room: initialValuesRoom.room,
        distType: initialValuesRoom.distType
    });

    const [selectAgeValue, setSelectAgeValue] = useState(() => {
        const initialSelectAgeValue =
            JSON.parse(localStorage.getItem("selectAgeValue")) || queryParamsRoom.getAll("age") || [];
        const initialChildren = parseInt(
            initialValuesRoom.children || queryParamsRoom.getAll("group_children") || 0
        );
        if (initialChildren > initialSelectAgeValue.length) {
            const diff = initialChildren - initialSelectAgeValue.length;
            return initialSelectAgeValue.concat([...Array(diff)].fill("14"));
        } else {
            return initialSelectAgeValue.slice(0, initialChildren);
        }
    });
        
    const [open, setOpen] = useState(false);

    const handleMenuClick = useCallback((e) => {
        if (e.key === '3') {
            setOpen(false);
        }
    }, []);

    const handleOpenChange = useCallback((flag) => {
        setOpen(flag);
    }, []);

    const disabledDate = useMemo(() => {
        return (current) => {
            return current && current.isBefore(dayjs().startOf("day"));
        };
    }, []);

    const handleChangeInfos = useCallback((type, action) => {
        setInfosState(prevState => {
            const newState = {
                ...prevState,
                [type]: action === 'increment' ? prevState[type] + 1 : prevState[type] - 1,
            };
            // Remettre à zéro la valeur de selectAgeValue si children est égal à zéro
            if (type === 'children' && newState[type] === 0) {
                setSelectAgeValue([]);
            }
            // Mettre à jour selectAgeValue en fonction de la nouvelle valeur de children
            if (type === 'children') {
                const newChildren = newState[type];
                if (newChildren > selectAgeValue.length) {
                    const diff = newChildren - selectAgeValue.length;
                    const newValues = selectAgeValue.concat([...Array(diff)].fill('14'));
                    setSelectAgeValue(newValues);
                } else {
                    const newValues = selectAgeValue.slice(0, newChildren);
                    setSelectAgeValue(newValues);
                }
            }
            return newState;
        });
    }, [selectAgeValue]);

    const handleSelectChange = useCallback((value, index) => {
        const updatedValues = [...selectAgeValue];
        updatedValues[index] = value;
        setSelectAgeValue(updatedValues);
    }, [selectAgeValue]);

    const getLabel = useMemo(() => ({ label, count }) => (
        <div className="label">
            {label}{count > 1 && ('s')}
        </div>
    ), []);

    const generateSelects = useMemo(() => (numSelects) => {
        const selectAgeInLocalStorage = JSON.parse(localStorage.getItem('selectAgeValue')) || [];
        const numChildren = parseInt(initialValuesRoom.children || queryParamsRoom.get('group_children') || 0);
        let selectAge;
        if (numChildren > selectAgeInLocalStorage.length) {
            const diff = numChildren - selectAgeInLocalStorage.length;
            selectAge = selectAgeInLocalStorage.concat([...Array(diff)].fill('14'));
        } else {
            selectAge = selectAgeInLocalStorage.slice(0, numChildren);
        }
        
        const selects = [];
        for (let i = 0; i < numSelects; i++) {
            if (i < numChildren) {
            selects.push(
                <Select
                    key={i}
                    size="large"
                    defaultValue={selectAge[i] || '14'}
                    onChange={(value) => handleSelectChange(value, i)}
                >
                {[...Array(18).keys()].map((val) => (
                    <Select.Option key={val} value={val}>{val}</Select.Option>
                ))}
                </Select>
            );
            } else {
            selects.push(
                <Select
                    key={i}
                    size="large"
                    defaultValue="14"
                    onChange={(value) => handleSelectChange(value, i)}
                >
                {[...Array(18).keys()].map((val) => (
                    <Select.Option key={val} value={val}>{val}</Select.Option>
                ))}
                </Select>
            );
            }
        }
        
        // Update the state with the new values
        if (numChildren > selectAge.length) {
            const diff = numChildren - selectAge.length;
            setSelectAgeValue([...selectAge, ...[...Array(diff)].fill('14')]);
        } else if (numChildren < selectAge.length) {
            const newSelectAge = selectAge.slice(0, numChildren);
            setSelectAgeValue(newSelectAge);
        }
        
        return selects;
    }, [queryParamsRoom, handleSelectChange, initialValuesRoom.children]);

    const getSelectAgeValue = () => {
        return selectAgeValue.join(',');
    }

    const items = [
        {
            label: <StyledDropdownItem className="inline">
                {getLabel({ label: 'Adulte', count: infosState.adults })}
                <CounterButton 
                    value={infosState.adults}
                    onDecrement={() => handleChangeInfos('adults', 'decrement')}
                    onIncrement={() => handleChangeInfos('adults', 'increment')}
                    isDisabled={infosState.adults === 1}
                />
            </StyledDropdownItem>,
            key: '1',
        },
        {
            label: <>
            <StyledDropdownItem className="inline">
                {getLabel({ label: 'Enfant', count: infosState.children })}
                <CounterButton 
                    value={infosState.children}
                    onDecrement={() => handleChangeInfos('children', 'decrement')}
                    onIncrement={() => handleChangeInfos('children', 'increment')}
                    isDisabled={infosState.children === 0}
                />
            </StyledDropdownItem>
            {infosState.children > 0 && (
                <Row align="end" style={{marginTop: 5}} className="list-select">
                    <Space>
                        {generateSelects(infosState.children, handleSelectChange)}
                    </Space>
                </Row>
            )}
            </>,
            key: '2',
        },
        {
            label: <StyledDropdownItem className="inline">
                {getLabel({ label: 'Chambre', count: infosState.room })}
                <CounterButton 
                    value={infosState.room}
                    onDecrement={() => handleChangeInfos('room', 'decrement')}
                    onIncrement={() => handleChangeInfos('room', 'increment')}
                    isDisabled={infosState.room === 1}
                />
            </StyledDropdownItem>,
            key: '4',
        },
        {
            label: <Row justify="end"><Button type="primary" size="large">Terminer </Button></Row>,
            key: '3',
        },
    ];

    const handleSearch = debounce(async (value) => {
        if (value !== null) {
            const dataSource = await axios.get(`${config.API_URL}/autocomplete`, {
                params: {
                    name: encodeURIComponent(value),
                },
            });
            setDataSource(dataSource.data);
        } else {
            setDataSource([]);
        }
    }, 300);
    
    const handleSelect = useCallback((option) => {
        setValue(option?.name);
        setDistType(option?.distType);
        setDistId(option?.distId);
    }, [setDistType, setDistId]);

    const loadMoreHotels = useCallback(async () => {
        setLastLoadTime(Date.now()); 
        if (pagination === paginationCount || pagination > paginationCount) {
            return;
        }
        setLoading(true);
        try {
            const response = await axios.get(`${config.API_URL}/hotels`, ({
                params: {
                    ...searchData,
                    page: pagination,
                }
            }));
            setHotels(prevHotels => prevHotels.concat(response?.data?.results));
            setPagination(prevPagination => prevPagination + 1);
        } catch (error) {
            notification.error({
                message: "Erreur de recherche d'hôtels",
                description: "Une erreur s'est produite lors de la recherche d'hôtels. Veuillez réessayer plus tard.",
                placement: "bottomLeft"
            });    
        } finally {
            setLoading(false);
        }
    }, [pagination, searchData, setHotels, paginationCount]);

    useEffect(() => {
        if(!isHomePage) {
            const destinationDiv = document.getElementById('roomseaker');
            const destinationTop = destinationDiv.offsetTop;
            window.scrollTo({ top: destinationTop - 20, behavior: 'smooth' });
        }
    }, [isHomePage]);

    const handleScroll = useCallback(() => {
        if(!isHomePage && isSearchPage){
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            const clientHeight = document.documentElement.clientHeight || window.innerHeight;
        
            const listHotelsShow = document.getElementById('list-hotels-show');
            const listHotelsShowRect = listHotelsShow?.getBoundingClientRect();
            const listHotelsShowOffsetTop = listHotelsShowRect?.top + scrollTop;
            const listHotelsShowHeight = listHotelsShowRect?.height;
        
            // Vérifier si la position de la div est en vue
            const isListHotelsShowInView = scrollTop + clientHeight >= listHotelsShowOffsetTop + listHotelsShowHeight;
            if (isListHotelsShowInView && !loadingMoreHotels) {
                setLoadingMoreHotels(true);
                const timeSinceLastLoad = Date.now() - lastLoadTime;
                if ((timeSinceLastLoad >= THROTTLE_TIME) && (hotels?.length > 0)) {
                    setLoadingMoreHotels(true);
                    loadMoreHotels().finally(() => {
                        setLoadingMoreHotels(false);
                    });
                }
            }
        }
    }, [hotels, isHomePage, isSearchPage, lastLoadTime, loadMoreHotels, loadingMoreHotels]);
    
    const handleModalMap = useCallback(() => {
        setModalHotelMap(true);
    }, []);

    const handleBottomSheet = useCallback(() => {
        setIsOpenBottomSheet(true);
    }, []);

    const onChangeSortBy = useCallback((e) => {
        setSortBy(e.target.value);
        setIsOpenBottomSheet(false);
        window.scrollTo({ top: 600, behavior: 'smooth' });
    }, []);

    const handleModalFilters = useCallback(() => {
        setModalFilters(true);
    }, []);
    
    useEffect(() => {
        setDistType(distType || initialValuesRoom.distType);
        localStorage.setItem("selectAgeValue", JSON.stringify(selectAgeValue));
            
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    
    }, [distType, handleScroll, initialValuesRoom.distType, selectAgeValue, setDistType]);
        
    const updateSearchData = useCallback((newSearchData) => {
        setSearchData(newSearchData);
    }, []);


    const handleSubmitForm = useCallback(() => {
        setSubmitFromForm(true);
        setSelectedFilters([]);
        form.submit();
    }, [form]);

    const onFinish = async (values) => {
        try {
            setLoading(true);
            setPagination(0);
            setFirstLoad(true);
            setHotels([]);
            const startDate = dayjs(values.dates[0]).format("YYYY-MM-DD");
            const endDate = dayjs(values.dates[1]).format("YYYY-MM-DD");
            let filtersTransform = selectedFilters.length > 0 ? selectedFilters.join(', ') : null;

            if(isSubmitFromForm){
                filtersTransform = null;
            }
            setSubmitFromForm(false);

            const newSearchData = {
                city: values.city,
                checkin: startDate,
                checkout: endDate,
                adults: infosState.adults,
                children: infosState.children || null,
                room: infosState.room || null,
                distId: distId || null,
                distType: distType || null,
                children_ages: getSelectAgeValue() || null,
                categories_filter_ids: filtersTransform || null
            };

            const data = await axios.get(`${config.API_URL}/hotels`, ({
                params: {
                    ...newSearchData
                }
            }));
            setDistId(''); // éviter les conflicts de doublon
            setTitle(values.city);
            setPaginationCount(Math.round(data?.data?.count / data?.data?.results?.length));
            updateSearchData(newSearchData);
            setDistType(data?.data?.dest_type);
            setLocalisationMap(data?.data?.map);
            setCountDays(data?.data?.countDays);
            setFiltersList(data?.data?.filters);
            setHotels(data?.data?.results);
            setCountResults(data?.data?.count);
            setPagination(1);
            setLoading(false);

            const queryParams = new URLSearchParams({
                city: values.city,
                checkin: startDate,
                checkout: endDate,
                adults: infosState.adults,
                ...(infosState.children && { group_children: infosState.children }),
                ...(infosState.room && { no_rooms: infosState.room }),
                dest_type: data?.data?.dest_type || infosState.distType || null
            });

            for (let i = 0; i < selectAgeValue.length; i++) {
                queryParams.append('age', selectAgeValue[i]);
            }

            localStorage.setItem("searchFormValues", JSON.stringify({
                city: values.city,
                checkinDate: startDate,
                distType: data?.data?.dest_type || null,
                checkoutDate: endDate,
                adults: infosState.adults,
                children: infosState.children || null,
                room: infosState.room || null,
                children_ages: getSelectAgeValue() || null
            }));
            history.push(`/search?${queryParams.toString()}`);
            setQueryParams(queryParams);
        } catch (error) {
            console.error(error);
            notification.error({
                message: "Erreur de recherche d'hôtels",
                description:
                    "Une erreur s'est produite lors de la recherche d'hôtels. Veuillez réessayer plus tard.",
                placement: "bottomLeft",
            });
            setLoading(false);
        }
    };

    const searchParams = new URLSearchParams(location.search);

    const initialValues = {
        city: searchParams.get("city") || "",
        dates: [
            searchParams.get("checkin")
            ? dayjs(searchParams.get("checkin"))
            : defaultDates[0],
            searchParams.get("checkout")
            ? dayjs(searchParams.get("checkout"))
            : defaultDates[1],
        ],
        adults: parseInt(searchParams.get("adults")) || 1,
        children: parseInt(searchParams.get("group_children")) || undefined,
        room: parseInt(searchParams.get("no_rooms")) || 1,
        children_ages: parseInt(searchParams.getAll("age")) || undefined,
        distType: distType || undefined,
    };
    
    const localStorageValues = JSON.parse(localStorage.getItem("searchFormValues"));
    if (localStorageValues) {
        initialValues.city = localStorageValues.city || initialValues.city;
        initialValues.dates[0] = localStorageValues.checkinDate
        ? dayjs(localStorageValues.checkinDate)
        : initialValues.dates[0];
        initialValues.dates[1] = localStorageValues.checkoutDate
        ? dayjs(localStorageValues.checkoutDate)
        : initialValues.dates[1];
        initialValues.adults = parseInt(localStorageValues.adults) || initialValues.adults;
        initialValues.children = parseInt(localStorageValues.children) || initialValues.children;
        initialValues.room = parseInt(localStorageValues.room) || initialValues.room;
        initialValues.children_ages = parseInt(localStorageValues.children_ages) || selectAgeValue;
        initialValues.distType = parseInt(localStorageValues.distType) || distType;
    }

    const sortedHotels = hotels && hotels.sort((a, b) => {
        if (sortBy === 'priceasc' && (a.price_breakdown) && (b.price_breakdown)) {
            return a.price_breakdown?.gross_price - b.price_breakdown?.gross_price;
        } else if (sortBy === 'pricedesc' && a.price_breakdown && b.price_breakdown) {
            return b.price_breakdown?.gross_price - a.price_breakdown?.gross_price;
        } else if (sortBy === 'distance') {
                return a.distance - b.distance;
        } else if (sortBy === 'review') {
            return b.review_score - a.review_score;
        } else if (sortBy === 'class_descending') {
            return b.class - a.class;
        } else if (sortBy === 'class_ascending') {
            return a.class - b.class;
        } 
        else {
            return 0;
        }
    });

    const iconsMap = {
        di: <BiMap />,
        re: <BiMap />,
        ai: <TbPlaneTilt />,
        ho: <RiHotelLine />,
        ci: <BiMap />,
        ap: <BiHotel />,
        la: <FaLandmark />
    };

    const renderOption = (option) => {

        const handleOptionClick = () => {
            handleSelect(option);
        };

        return (
            <StyledOptions align="middle" wrap={false} onClick={handleOptionClick}>
                <Col>
                    <div className="icon">
                        {iconsMap[option.type] || <BiMap />}
                    </div>
                </Col>
                <Col>
                    <Paragraph ellipsis strong style={{marginBottom: 0}}>
                        {option.name}
                    </Paragraph>
                    <Paragraph ellipsis type="secondary" style={{marginBottom: 0}}>
                        {option.country}{option.region && `, ${option.region}`}
                    </Paragraph>
                </Col>
            </StyledOptions>
        );
    };

    const options = dataSource.map((item, i) => ({
        value: item.name,
        data: item,
        key: i,
        label: renderOption(item)
    }));

    const renderForm = () => {
        return (
            <StyledWrapperForm className="form-wrapper" id="roomseaker">
                <Form 
                    onFinish={onFinish} 
                    initialValues={initialValues} 
                    layout={screens?.xs ? 'vertical' : 'inline'}
                    size="large"
                    form={form}
                >
                    <div className="item-wrapper">
                        <div className="label">
                            Où allez-vous ?
                        </div>
                        <Form.Item 
                            name="city"
                            rules={[{ required: true, message: '' }]}
                        >
                            <AutoComplete
                                value={value}
                                options={options}
                                onSelect={() => {}}
                                onChange={handleSearch}
                                dropdownMatchSelectWidth={screens?.xs ? 282 : 400}
                                notFoundContent="Aucun résultat"
                            >
                                <Input
                                    prefix={<BiBed className="site-form-item-icon" />}
                                    placeholder="Paris" 
                                />
                            </AutoComplete>
                        </Form.Item>
                    </div>
                    <div className="item-wrapper">
                        <div className="label">
                            Quand souhaitez-vous partir ?
                        </div>
                        <Form.Item
                            name="dates"
                            rules={[{ type: "array", required: true, message: '' }]}
                        >
                            <RangePicker 
                                placeholder={['Date d\'arrivée', 'Date de départ']} 
                                disabledDate={disabledDate} 
                                placement="bottomLeft"
                                value={initialValues.dates}
                            />
                        </Form.Item>
                    </div>

                    <Dropdown
                        getPopupContainer={() => document.getElementById('roomseaker')}
                        trigger={['click']}
                        menu={{
                            items,
                            onClick: handleMenuClick,
                        }}
                        onOpenChange={handleOpenChange}
                        open={open}
                        overlayClassName="user-selector"
                    >
                        <div className="item-wrapper" onClick={(e) => e.preventDefault()}>
                            <div className="label">Personnes</div>
                            <StyledFakeInput className="fake-input">
                                <div className="icon">
                                    <BiUser />
                                </div>
                                <div className="fake-value">
                                    {infosState.adults} adulte{infosState.adults > 1 && ('s')},{' '}
                                    {infosState.children} enfant{infosState.children > 1 && ('s')},{' '} 
                                    {infosState.room} chambre{infosState.room > 1 && ('s')}
                                </div>
                            </StyledFakeInput>
                        </div>
                    </Dropdown>
                    <Form.Item>
                        <Button onClick={handleSubmitForm} type="primary" loading={loading}>
                            Recherche
                        </Button>
                    </Form.Item>
                </Form>
            </StyledWrapperForm>
        );
    };

    return (
        <>
        {isHomePage && loading && (
            <StyledLoaderApp 
                className={classNames('loader-app', { active: isHomePage && loading })}
            >
                <div className="wrapper">
                    <Lottie
                        options={defaultOptions}
                        height={150}
                        width={150}
                    />
                    <div className="text">
                        <Title level={3} style={{marginBottom: 0}}>Un instant</Title>
                        <Paragraph strong>Nous recherchons les meilleurs prix</Paragraph>
                    </div>
                </div>
            </StyledLoaderApp>
        )}
        {screens?.xs ? (
            renderForm()
        ) : (
            <StyledAffix offsetTop={0}>
                {renderForm()}
            </StyledAffix>
        )}
        {isHomePage && (
            <>
            <StyleListingWrapper>
                <div className="container">
                    <div className="container-slider">
                        <div className="container-slider--title">
                            <Title level={3}>Les villes les plus populaires</Title>
                        </div>
                        <Slider
                            {...settings}
                        >
                            {cityPlaceholder.map((cityInfo, index) => (
                                <SmallCard
                                    key={index}
                                    title={cityInfo.city}
                                    description={cityInfo.description}
                                    src={cityInfo.image}
                                    onClick={() => handleSelectDestination(cityInfo)}
                                />
                            ))}
                        </Slider>
                    </div>
                </div>
            </StyleListingWrapper>
            <Arguments />
            <Rassurance />
            <StyleListingWrapper>
                <div className="container">
                    <div className="container-slider">
                        <div className="container-slider--title">
                            <Title level={3}>Inspiration pour vos prochaines vacances</Title>
                        </div>
                        <Slider
                            {...settings}
                        >
                            {countryPlaceholder.map((countryInfo, index) => (
                                <SmallCard 
                                    key={index}
                                    title={countryInfo.country}
                                    description={countryInfo.description}
                                    src={countryInfo.image}
                                    onClick={() => handleSelectDestination(countryInfo)}
                                />
                            ))}
                        </Slider>
                    </div>
                </div>
            </StyleListingWrapper>
            <Newsletter />
            </>
        )}

        {!isHomePage && firstLoad && !loading && hotels?.length === 0 && (
            <Placeholder 
                image={noresult}
                title="Aucun résultat"
                description="Veuillez vérifier ce que vous avez indiqué comme recherche"
            />
        )}
        {isSearchPage && !firstLoad && !loading && hotels?.length === 0 && (
            <Placeholder 
                lottie={
                    <Lottie
                        options={defaultOptions}
                        height={150}
                        width={150}
                    />
                }
                title="Trouvez votre hébergement idéal"
                description="Entrez vos critères de recherche pour découvrir les meilleures offres d'hôtels disponibles."
            />
        )}
        {loading && (
            <StyleListingWrapper>
                <div className="container">
                    <Row gutter={[16, 16]}>
                        {Array(12).fill().map((_, index) => (
                            <Col key={index} xs={24} sm={12} md={8}>
                                <SkeletonCard />
                            </Col>
                        ))}
                    </Row>
                </div>
            </StyleListingWrapper>
        )}
        {isSearchPage && hotels?.length > 0 && (
            <StyleListingWrapper >
                <div className="container">
                    {hotels && (
                        <Row 
                            align="bottom"
                            justify="space-between"
                            className={classNames('header', { mobile: screens?.xs })}
                        >
                            <div className="header-title">
                                {initialValues.city || 'Pragues'}, <span>{countResults || '0'} résultats</span>
                            </div>
                            <div className="header-wrapper">
                                <Space>
                                    <div className="btn">
                                        <Button 
                                            size="large"
                                            onClick={() => handleModalFilters()}
                                            icon={<BiFilter />}
                                        >
                                            Filtrer {' '}<Badge count={selectedFilters?.length} />
                                        </Button>
                                    </div>
                                    <div className="btn">
                                        <Button 
                                            size="large"
                                            icon={<BiMap />}
                                            onClick={() => handleModalMap()}
                                        >
                                            {screens?.xs ? (
                                                'Carte'
                                            ) : (
                                                'Voir sur la carte'
                                            )}
                                        </Button>
                                    </div>
                                    {screens?.xs ? (
                                        <Button 
                                            size="large"
                                            icon={<BiSortAlt2 />}
                                            onClick={() => handleBottomSheet()}
                                        >
                                            Trier <Badge dot={sortBy !== 'priceasc'}/>
                                        </Button>
                                        ) :(
                                        <div className="sortby">
                                            <Form.Item>
                                                <Select suffixIcon={<BiMap />} size="large" value={sortBy} onChange={value => setSortBy(value)}>
                                                    <Select.Option value="priceasc">Tarif (du - cher au + cher)</Select.Option>
                                                    <Select.Option value="pricedesc">Tarif (du + cher au - cher)</Select.Option>
                                                    <Select.Option value="distance">Distance du centre-ville</Select.Option>
                                                    <Select.Option value="review">Note des commentaires clients</Select.Option>
                                                    <Select.Option value="class_descending">Étoiles (5 à 0)</Select.Option>
                                                    <Select.Option value="class_ascending">Étoiles (0 à 5)</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>        
                                    )}
                                </Space>
                            </div>
                        </Row>
                    )}
                    <Row gutter={[16, 16]} id="list-hotels-show">
                        {sortedHotels.map((hotel, i) => (
                            <Col key={`global-${i}`} xs={24} sm={12} md={8}>
                                {hotel.ribbon_text ? (
                                    <Badge.Ribbon placement="start" text={hotel.ribbon_text} color={colors.ribbon}>
                                        <HotelCard 
                                            data={hotel}
                                            countDays={countDays}
                                            params={queryParams}
                                            setUrlHotel={setUrlHotel}
                                            setHotelId={setIsHotelId}
                                            setTitleModal={setTitleModal}
                                            setmodalHotelStars={setmodalHotelStars}
                                            setIsOpenModalPhotos={setIsOpenModalPhotos}
                                            setIsOpenModalMap={setOpenModalUnicast}
                                            setLocalisationMapUnicast={setLocalisationMapUnicast}
                                            setOpenModalHotelMap={setOpenModalHotelMap}
                                            isMap
                                            price={{
                                                grossAmount: hotel.composite_price_breakdown?.gross_amount?.value,
                                                grossAmountNight: hotel.composite_price_breakdown?.gross_amount_per_night?.value,
                                                strikethroughAmount: hotel.composite_price_breakdown?.strikethrough_amount?.value,
                                                strikethroughAmountNight: hotel.composite_price_breakdown?.strikethrough_amount_per_night?.value,
                                                currency: hotel.composite_price_breakdown?.gross_amount_per_night?.currency,
                                            }}
                                        />
                                    </Badge.Ribbon>
                                ) : (
                                    <HotelCard 
                                        data={hotel} 
                                        countDays={countDays}
                                        params={queryParams} 
                                        setUrlHotel={setUrlHotel}
                                        setHotelId={setIsHotelId}
                                        setmodalHotelStars={setmodalHotelStars}
                                        setTitleModal={setTitleModal}
                                        isMap
                                        setIsOpenModalPhotos={setIsOpenModalPhotos}
                                        setIsOpenModalMap={setOpenModalUnicast}
                                        setOpenModalHotelMap={setOpenModalHotelMap}
                                        setLocalisationMapUnicast={setLocalisationMapUnicast}
                                        price={{
                                            grossAmount: hotel.composite_price_breakdown?.gross_amount?.value,
                                            grossAmountNight: hotel.composite_price_breakdown?.gross_amount_per_night?.value,
                                            strikethroughAmount: hotel.composite_price_breakdown?.strikethrough_amount?.value,
                                            strikethroughAmountNight: hotel.composite_price_breakdown?.strikethrough_amount_per_night?.value,
                                            currency: hotel.composite_price_breakdown?.gross_amount_per_night?.currency,
                                        }}
                                    />
                                )}
                            </Col>
                        ))}
                        {loading && (
                            Array(6).fill().map((_, index) => (
                                <Col key={index} xs={24} sm={12} md={8}>
                                    <SkeletonCard />
                                </Col>
                            ))
                        )}
                    </Row>
                    {(pagination === paginationCount || pagination > paginationCount) && (
                        <Row align="middle" justify="center" style={marginTop}>
                            <Empty
                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                imageStyle={imageHeightEmpty}
                                description={
                                    <span>Vous êtes arrivés au bout</span>
                                }
                            />
                        </Row>
                    )}
                </div>

            </StyleListingWrapper>
        )}
            <ModalHotelPhotos
                isOpen={isOpenModalPhotos}
                title={titleModal}
                hotelStars={modalHotelStars}
                _hotel={_hotel}
                href={urlHotel}
                closeModal={() => setIsOpenModalPhotos(false)}
            />
            <ModalMap
                params={queryParams}
                isOpen={modalHotelMap}
                title={`Hôtels près de ${initialValues.city}`}
                centeredMap={localisationMap}
                hotels={hotels}
                closeModal={() => setModalHotelMap(false)}
            />
            <ModalMapUnicast
                href={urlHotel}
                isOpen={modalHotelMapUnicast}
                title={titleModal}
                hotel={selectedHotelMapUnicast}
                centeredMap={localisationMapUnicast}
                closeModal={() => setOpenModalUnicast(false)}
            />
            <ModalFilters
                title={`Filtre pour : ${initialValues.city}`}
                params={queryParams}
                isOpen={modalFilters}
                filtersList={filters}
                filtersSelected={selectedFilters}
                setFilters={setSelectedFilters}
                closeModal={() => setModalFilters(false)}
                form={form}
            />
            {screens?.xs && (
                <StyledBottomSheet 
                    className={classNames('bottom-sheet', { active: isOpenBottomSheet })}
                >
                    <div className="bottom-sheet--overlay" onClick={() => setIsOpenBottomSheet(false)} />
                    <div className="bottom-sheet--wrapper">
                        <div className="bottom-sheet--line-header">
                            <div className="title">Trier par</div>
                            <div className="close" onClick={() => setIsOpenBottomSheet(false)}><BiX /></div>
                        </div>
                        <div className="content">
                            <Radio.Group value={sortBy} onChange={onChangeSortBy}>
                                <Space direction="vertical">
                                    <Radio value="priceasc">Tarif (du - cher au + cher)</Radio>
                                    <Radio value="pricedesc">Tarif (du + cher au - cher)</Radio>
                                    <Radio value="distance">Distance du centre-ville</Radio>
                                    <Radio value="review">Note des commentaires clients</Radio>
                                    <Radio value="class_descending">Étoiles (5 à 0)</Radio>
                                    <Radio value="class_ascending">Étoiles (0 à 5)</Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                    </div>
                </StyledBottomSheet>
            )}
        </>
    );
};
