import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Modal, Col, Space, Typography, Row, Button } from 'antd';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { StarFilled } from '@ant-design/icons';
import { StyledPopup } from "@/utils/styles";
import { AppContext } from '@/AppContext';

delete L.Icon.Default.prototype._getIconUrl;

const { Title, Text } = Typography;

const customMarkerIcon = (price, isActive) => L.divIcon({
    className: `custom-marker-icon ${isActive ? 'active' : ''}`,
    html: `<div class="price-label ${isActive ? 'active' : ''}">${price}€</div>`,
});


export const ModalMapUnicast = ({ isOpen, title, hotel, closeModal, centeredMap, href }) => {
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [leftValueMapPopup, setLeftValueMapPopup] = useState(null);
    const { exchangeRatesCache } = useContext(AppContext);

    const convertAmount = useCallback((amount, currency) => {
        if (currency === "EUR") {
            return amount?.toFixed(2);
        } else if (exchangeRatesCache) {
            const rate = exchangeRatesCache.exchange_rates?.find(
                (rate) => rate.currency === currency
            );
            if (rate) {
                return (amount / parseFloat(rate.exchange_rate_buy))?.toFixed(2);
            } else {
                return amount?.toFixed(2);
            }
        }
        return null;
    }, [exchangeRatesCache]);

    useEffect(() => {
        let timeoutId;
        if (leftValueMapPopup && leftValueMapPopup !== '0px') {
            const divPopup = document.querySelector('.leaflet-popup');
            if (divPopup && divPopup.style) {
                const updateLeftValue = () => {
                    divPopup.style.left = leftValueMapPopup;
                };

                timeoutId = setTimeout(updateLeftValue, 50);
            }
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [leftValueMapPopup, isOpen, selectedHotel]);

    const handleModalClose = () => {
        setSelectedHotel(null);
        closeModal();
        const divPopup = document.querySelector('.leaflet-popup');
        if(!!divPopup){
            const styles = window.getComputedStyle(divPopup);
            const leftValue = styles.getPropertyValue('left');
            setLeftValueMapPopup(leftValue);
        }
    };        
    
    const ComponentResize = () => {
        const map = useMap();
        useEffect(() => {
            const timeout = setTimeout(() => {
                map.invalidateSize();
            }, 100);
            
            return () => clearTimeout(timeout);
        }, [map]);
        return null;
    };

    const handleSelectMarker = useCallback((hotel) => {
        setSelectedHotel(hotel);
    }, []);

    const ResetMapView = ({ center }) => {
        const map = useMap();
        useEffect(() => {
        if (center) {
            map.setView(center, 13);
        }
        }, [center, map]);
        
        return null;
    };
    
    return (
        <Modal
            centered
            width={'95%'}
            title={title}
            open={isOpen}
            footer={null}
            onCancel={handleModalClose}
        >
            <MapContainer
                scrollWheelZoom={false}
                center={[
                    centeredMap?.lat,
                    centeredMap?.lon,
                ]}
                zoom={13}
                style={{ height: '80vh', width: '100%' }}
            >
                <ResetMapView center={[centeredMap?.lat, centeredMap?.lon]} />
                <ComponentResize />
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker
                    key={hotel?.hotel_id}
                    position={[hotel?.latitude, hotel?.longitude]} 
                    icon={customMarkerIcon(Math.round(
                        convertAmount(
                            hotel.composite_price_breakdown?.gross_amount_per_night?.value,
                            hotel.composite_price_breakdown?.gross_amount_per_night?.currency
                        )
                    ), selectedHotel?.hotel_id === hotel?.hotel_id)}
                    eventHandlers={{
                        click: () => {
                            handleSelectMarker(hotel);
                        },
                    }}
                >
                    <Popup autoPan minWidth={300} maxWidth={300}>
                        <StyledPopup justify="start" align="top" wrap={false}>
                            <Space align="start" wrap={false}>
                                <Col align="start">
                                    <div className="">
                                        <img src={hotel?.main_photo_url} alt={hotel?.hotel_name} />
                                    </div>
                                </Col>
                                <Col align="start">
                                    <Title level={5}>{hotel?.hotel_name}</Title>
                                    {hotel?.class > 0 && (
                                        <div className="star">
                                            {Array.from({ length: hotel?.class }, (_, index) => (
                                                <StarFilled key={index} />
                                            ))}
                                        </div>
                                    )}
                                    <div className="review">
                                        <div className="review--title">{hotel?.review_score_word || 'Aucun avis'}</div>
                                        {hotel?.review_score_word && (
                                            <div className="review--notation">
                                                - <b>{hotel?.review_score}</b>/10 <span>({hotel?.review_nr && hotel?.review_nr} avis)</span>
                                            </div>
                                        )}
                                    </div>

                                    {hotel.soldout !== 1 && (
                                        <Row 
                                            align="middle" 
                                            justify="space-between" 
                                            className="line-price"
                                        >
                                            <div className="price">
                                                
                                                {hotel.composite_price_breakdown?.strikethrough_amount_per_night?.value > 0 && (
                                                    <span className="strikethrough">
                                                        <strike>
                                                            {Math.round(convertAmount(
                                                                hotel.composite_price_breakdown?.strikethrough_amount_per_night?.value,
                                                                hotel.composite_price_breakdown?.gross_amount_per_night?.currency
                                                            ))}€
                                                        </strike>
                                                    </span>
                                                )}
                                                <span className="pricing">
                                                    {Math.round(convertAmount(
                                                        hotel.composite_price_breakdown?.gross_amount_per_night?.value, 
                                                        hotel.composite_price_breakdown?.gross_amount_per_night?.currency
                                                    ))}€<span>/nuit</span>
                                                </span>
                                            </div>

                                            <Button 
                                                target="_blank"
                                                href={href}
                                                type="primary"
                                            >
                                                Voir l'offre
                                            </Button>
                                        </Row>
                                    )}
                                    {hotel.soldout === 1 && (
                                        <Row 
                                            align="middle" 
                                            justify="space-between" 
                                            className="line-price"
                                        >
                                            <Text type="danger">Produit épuisé</Text>
                                        </Row>
                                    )}

                                </Col>
                            </Space>
                        </StyledPopup>
                    </Popup>
                </Marker>
            </MapContainer>
            
        </Modal>
    );
};
