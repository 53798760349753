import { StyledPlaceholder } from "@/utils/styles";

export const Placeholder = ({ image, title, description, lottie }) => {
    return (
        <StyledPlaceholder>
            {image && (
                <div className="image">
                    <img src={image} alt={title} />    
                </div>
            )}
            {lottie && (lottie)}
            <div className="text-content">
                <div className="title">
                    {title}
                </div>
                <div className="desc">
                    {description}
                </div>
            </div>
        </StyledPlaceholder>
    );
}
