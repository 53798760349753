import React, { useEffect } from 'react';
import { HotelSearchForm } from '@/components/search';
import { Footer } from "@/components/footer";
import { Header } from "@/components/header";
import { Typography } from 'antd';
import { StyledContainer } from '@/utils/styles';

const { Title, Paragraph } = Typography;

export const DataProtectionPage = ({ 
    isHomePage, 
    backgroundImage, 
    title, 
    description, 
    setDistType, 
    distType, 
    form, 
    handleSelectDestination,
    setTitle
}) => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);    

    return (
        <>
            <Header
                isHomePage={isHomePage}
                srcBg={backgroundImage}
                title={title}
                description={description}
            />
            <HotelSearchForm 
                isHomePage={isHomePage}
                handleSelectDestination={handleSelectDestination}
                form={form}
                setTitle={setTitle}
                setDistType={setDistType}
                distType={distType}
            />
            <StyledContainer>
                <div style={{ maxWidth: 800, margin: '0 auto' }}>
                    <Title style={{ textAlign: 'center', marginBottom: 50 }}>Protection des données</Title>
                    <Paragraph>
                        Nous prenons la protection de vos données personnelles très au sérieux et nous nous engageons à respecter votre vie
                        privée en ligne. Cette page de protection des données explique comment nous recueillons, utilisons et protégeons les
                        données personnelles que vous nous fournissez lorsque vous utilisez notre application.
                    </Paragraph>
                    <Paragraph>
                        Nous collectons les données personnelles que vous nous fournissez lorsque vous utilisez notre application, y compris
                        votre nom, votre adresse e-mail et votre adresse IP. Nous pouvons également collecter des données sur votre utilisation
                        de notre application, telles que les pages que vous consultez et les actions que vous effectuez.
                    </Paragraph>
                    <Paragraph>
                        Nous utilisons les données personnelles que vous nous fournissez pour améliorer notre application et vous fournir des
                        informations sur les hôtels et les prix. Nous pouvons également utiliser ces données pour vous contacter directement
                        par e-mail.
                    </Paragraph>
                    <Paragraph>
                        Nous ne partageons pas vos données personnelles avec des tiers, sauf si cela est nécessaire pour fournir nos services ou
                        si nous y sommes légalement obligés. Nous ne vendons jamais vos données personnelles à des tiers.
                    </Paragraph>
                    <Paragraph>
                        Nous prenons des mesures de sécurité raisonnables pour protéger vos données personnelles contre la perte, l'utilisation
                        abusive et l'accès non autorisé. Nous ne conservons pas vos données personnelles plus longtemps que nécessaire pour
                        fournir nos services ou pour respecter nos obligations légales.
                    </Paragraph>
                    <Paragraph>
                        Si vous souhaitez exercer vos droits en matière de protection des données, tels que le droit d'accès, de rectification
                        ou de suppression de vos données personnelles, veuillez nous contacter directement par e-mail.
                    </Paragraph>
                    <Paragraph>
                        Nous pouvons modifier cette politique de protection des données à tout moment, sans préavis. Nous vous encourageons à
                        consulter régulièrement cette page pour vous assurer que vous êtes au courant des dernières modifications.
                    </Paragraph>
                </div>
            </StyledContainer>
            <Footer
                onClick={(data) => handleSelectDestination(data)}
            />

        </>
    );
};

