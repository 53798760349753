import { useState } from 'react';
import { StyledNewsletter, StyleListingWrapper } from "@/utils/styles";
import { Typography, Col, Row, Input, Button, notification, Space, Form } from 'antd';

const { Title, Text } = Typography;
const API_URL = "https://script.google.com/macros/s/AKfycbx-Y9e1hpc0uPxrYJi1y8P76gj1zBbOKBKgLCt9WmPObl9ZvW9IUl-eDt78Q6G2DP6v/exec";

export const Newsletter = () => {

    const [email, setEmail] = useState("");
    const [isLoading, setLoading] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleFormSubmit = () => {
        const form = document.forms['google-sheet'];

        setLoading(true);
        fetch(API_URL, { method: 'POST', body: new FormData(form)})
            .then(() => {
                notification.success({
                    message: 'Félicitation !',
                    description: 'Votre email a été ajouté avec succès',
                    placement: 'bottomLeft',
                });
            })
            .catch(() => {
                notification.error({
                    message: 'Erreur',
                    description: "Impossible d'ajouter l'email.",
                    placement: 'bottomLeft',
                });
            })
            .finally(() => setLoading(false));
    };

    return (
        <StyleListingWrapper>
            <div className="container">
                <StyledNewsletter>
                    <Row align="middle" gutter={16}>
                        <Col span={24} lg={12}>
                            <Title level={3}>Recevez des offres exclusives, inspirations et informations sur les réglementations actuelles.</Title>
                            <Text>Abonnez-vous* et recevez de précieux conseils pour la planification de vos voyages !</Text>
                        </Col>
                        <Col className="a-m-t" span={24} lg={12}>
                            <Row align="top" wrap={false}>
                                <Form 
                                    onFinish={handleFormSubmit} 
                                    layout="inline" 
                                    size="large"
                                    name="google-sheet"
                                >
                                    <Space wrap>
                                        <Form.Item
                                            name="email"
                                            rules={[{ required: true, message: 'Veuillez entrer votre adresse mail.' }]}
                                        >
                                            <Input
                                                placeholder="Entrer votre adresse mail"
                                                value={email}
                                                size="large"
                                                name="email"
                                                type="email"
                                                onChange={handleEmailChange}
                                            />
                                        </Form.Item>
                                        <Button 
                                            type="primary" 
                                            htmlType="submit"
                                            size="large"
                                            loading={isLoading}
                                        >
                                            M'abonner !
                                        </Button>
                                    </Space>
                                </Form>
                            </Row>
                            <Typography style={{fontSize: 12, marginTop: 10}}>
                                En vous inscrivant, vous acceptez nos Conditions générales et notre Protection des données. 
                                *Vous recevrez des offres de BookHotel's et acceptez que nous partagions votre adresse e-mail chiffrée 
                                avec des tiers afin que nous puissions vous proposer des publicités plus utiles et plus pertinentes.
                            </Typography>
                        </Col>
                    </Row>
                </StyledNewsletter>
            </div>
        </StyleListingWrapper>
    );
};
