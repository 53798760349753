import { StyledArguments, StyleListingWrapper } from "@/utils/styles";
import { Typography, Col, Row } from 'antd';
import { BiTargetLock, BiDollar, BiHourglass, BiListUl} from 'react-icons/bi';

const { Text } = Typography;

export const Arguments = () => {

    const data = [
        {
            icon: <BiDollar />,
            label: "Économisez de l'argent" 
        },
        {
            icon: <BiHourglass />,
            label: "Gagnez du temps"
        },
        {
            icon: <BiListUl />,
            label: "Accédez à plus d'options"
        },
        {
            icon: <BiTargetLock />,
            label: "Faites un choix éclairé"
        }
    ]

    return (
        <StyleListingWrapper>
            <div className="container">
                <StyledArguments>
                    <Row align="start" justify="center" gutter={16}>
                        {data.map((item, i) => (
                            <Col className="item" key={i} align="center" sm={6} xs={12}>
                                <Row justify="center" align="middle">
                                    <div className="icon-wrapper">
                                        {item.icon}
                                    </div>
                                    <Text>
                                        {item.label}
                                    </Text>
                                </Row>
                            </Col>
                        ))}
                    </Row>
                </StyledArguments>
            </div>
        </StyleListingWrapper>
    );
};
