import { Skeleton } from 'antd';
import { StyledSkeleton } from "@/utils/styles";

export const SkeletonCard = () => {
    return (
        <StyledSkeleton>
            <Skeleton.Image active />
            <Skeleton loading={true} active />
        </StyledSkeleton>
    );
}
