import { createGlobalStyle } from 'styled-components';
import { colors } from '@/utils/colors';

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: Open-Sans, Helvetica, Sans-Serif;
        overflow-x: hidden;
        background-color: #FAFAFA;
    }
    h1,h2,h3,h4,h5, ol,ul,li,a,p,span{
        margin: 0;
        padding: 0;
    }
    h1, h2, h3, h4, h5{
        font-family: 'Josefin Sans',sans-serif;
    }
    .leaflet-control-attribution{
        display: none;
    }
    .leaflet-popup{
        width: 340px!important;
        &-content{
            .ant-btn{
                color: white;
            }
        }
    }
    .ant-picker-panel {
        &-container{
            margin-left: 0!important;
        }
        &:last-child {
            width: 0;
            .ant-picker-header {
                position: absolute;
                right: 0;
                .ant-picker-header-prev-btn, 
                .ant-picker-header-view {
                    visibility: hidden;
                }
            }

            .ant-picker-body {
                display: none;
            }

            @media (min-width: 768px) {
                width: 288px!important;
                .ant-picker-header {
                    position: relative;
                    .ant-picker-header-prev-btn, .ant-picker-header-view {
                        visibility: initial;
                        }
                    }

                    .ant-picker-body {
                        display: block;
                    }
                }
            }
        }
    }
    .leaflet-popup-tip-container{
        display: none;
    }
    .custom-marker-icon {
        min-width: 30px;
        &:hover,
        &.active{
            z-index: 9999!important;
        }
    }
    .price-label{
        white-space: nowrap;
        font-weight: bold;
        position: relative;
        padding: 2px 5px;
        border-radius: 4px;
        color: ${colors.white};
        text-align: center;
        background-color: ${colors.primaryLight};
        width: 100%;
        box-shadow: 0 0 10px rgba(0,0,0,.45);
        transition: all 250ms ease;
        &.active{
            background-color: ${colors.primaryDark};
            &:after{
                border-top-color: ${colors.primaryDark};
            }
        }
        &:hover{
            background-color: ${colors.primaryDark};
            &:after{
                border-top-color: ${colors.primaryDark};
            }
        }
        &:after{
            content: "";
            position: absolute;
            right: 50%;
            transform: translateX(50%);
            bottom: -8px;
            border-top: 8px solid ${colors.primaryLight};
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            border-bottom: none;
            transition: all 250ms ease;
        }
    }
    .my-masonry-grid {
        display: flex;
        margin-left: -8px;
        width: auto;
    }
    .my-masonry-grid_column {
        padding-left: 8px;
        background-clip: padding-box;
    }
    .ant-btn{
        display: flex;
        align-items: center;
        .ant-badge{
            position: absolute;
            left: 20px;
        }
        > svg{
            margin-right: 5px;
        }
    }
    .ant-modal{
        &-footer{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }
    }
`;

export default GlobalStyle;
