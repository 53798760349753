import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Typography } from 'antd';
import { StyledFooter } from "@/utils/styles";
import dayjs from 'dayjs';
import { countryPlaceholder, cityPlaceholder } from "@/utils/data";

const { Title } = Typography;

export const Footer = ({ onClick }) => {

    return (
        <StyledFooter>
            <div className="container">
                <Row align="top">
                    <Col span={24} lg={4}>
                        <div className="footer-container">
                            <Title level={5}>
                                LalyTrip ©{dayjs().year()}
                            </Title>
                        </div>
                    </Col>
                    <Col className="a-m-t" span={24} lg={5} md={6}>
                        <div className="footer-container">
                            <Title level={5}>Découvrir des villes</Title>
                            {cityPlaceholder.map((cityInfo, index) => (
                                <div
                                    className="item" 
                                    key={index}
                                    onClick={() => onClick(cityInfo)}
                                >
                                    {cityInfo.footerLabel}
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col className="a-m-t" span={24} lg={5} md={6}>
                        <div className="footer-container">
                            <Title level={5}>Découvrir des pays</Title>
                            {countryPlaceholder.map((countryInfo, index) => (
                                <div 
                                    key={index}
                                    className="item" 
                                    onClick={() => onClick(countryInfo)}
                                >
                                    {countryInfo.footerLabel}
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col className="a-m-t" span={24} lg={5} md={6}>
                        <div className="footer-container">
                            <Title level={5}>Contact</Title>
                            <a href="mailto:lalytrip@gmail.com" className="item">
                                Nous contacter
                            </a>
                            {/* <div className="item">
                                Devenez partenaire
                            </div>
                            <div className="item">
                                Espace publicitaire
                            </div> */}
                        </div>
                    </Col>
                    <Col className="a-m-t" span={24} lg={5} md={6}>
                        <div className="footer-container">
                            <Title level={5}>Termes et conditions</Title>
                            <Link to="/cgu" className="item">
                                Conditions générales
                            </Link>
                            <Link to="/protect-data" className="item">
                                Protection des données
                            </Link>
                            <Link to="/mentions" className="item">
                                Mentions légales
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>
        </StyledFooter>
    )
};
