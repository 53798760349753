import { StyledRassurance, StyleListingWrapper } from "@/utils/styles";
import { Typography, Col, Row, Button } from 'antd';
import placeholder from '@/statics/images/placeholder-rassurance@2x.png';

const { Title, Text } = Typography;

export const Rassurance = () => {

    const ScrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

    return (
        <StyleListingWrapper>
            <div className="container">
                <StyledRassurance>
                    <Row align="middle" gutter={16}>
                        <Col span={24} md={12} lg={12}>
                            <Title level={2}>Trouvez l'hôtel parfait en un instant avec notre comparateur d'hôtels.</Title>
                            <Text>
                                Comparez les prix de milliers d'hôtels dans le monde entier pour trouver l'option parfaite adaptée à vos besoins et à votre budget. 
                            </Text>
                            <div className="btn-wrapper">
                                <Button size="large" onClick={ScrollToTop}>
                                    Commencer ma recherche
                                </Button>
                            </div>
                        </Col>
                        <Col className="a-m-t" span={24} md={12} lg={12}>
                            <div className="img-wrapper">
                                <img src={placeholder} alt="Hotels aux meilleurs prix" />
                            </div>
                        </Col>
                    </Row>
                </StyledRassurance>
            </div>
        </StyleListingWrapper>
    );
};
