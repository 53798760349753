import { useContext } from 'react';
import bg from "@/statics/images/bg-homepage.jpg";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import { BsBalloonHeartFill } from "react-icons/bs";
import { AppContext } from '@/AppContext';
import Logo from '@/statics/images/lalytrip-white.svg';
import { StyledApp } from "@/utils/styles";

const { Text } = Typography;

const DEFAULT_TITLE = "Votre havre de paix parmi des milliers de logements.";
const DEFAULT_DESCRIPTION = "Comparez des milliers d’hôtels et des logements d’hôtes. Meilleur prix garanti";
const DEFAULT_BG = bg;

export const Header = ({ 
    isHomePage, 
    srcBg, 
    title, 
    description
}) => {
    const { countFav } = useContext(AppContext);

    return (
        <StyledApp style={{ padding: '24px' }} bg={isHomePage ? DEFAULT_BG : srcBg}>
            <header className="app-header">
                <nav>
                    <div className="logo">
                        <Link to="/">{<img src={Logo} alt="LalyTrip" title="LalyTrip" />}</Link>
                    </div>
                    <div className="menu">
                        <ul>
                            <li>
                                <Link to={'hotels-favoris'}>
                                    <Text strong>Favoris</Text>
                                    <div className="icon">{countFav} <BsBalloonHeartFill /></div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="text-wrapper">
                    <h1>{isHomePage ? DEFAULT_TITLE : title}</h1>
                    <h2>{isHomePage ? DEFAULT_DESCRIPTION : description}</h2>
                </div>
            </header>
            <span className="overlay" />
        </StyledApp>
    );
};
