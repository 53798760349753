import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout, ConfigProvider, Form } from 'antd';
import { HomePage } from '@/components/homepage';
import { Mentions } from '@/components/mentions';
import { Favourites } from '@/components/PageHotelFav';
import { TermsPage } from '@/components/cgu';
import { DataProtectionPage } from '@/components/protectdata';
// import { HotelPage } from '@/components/hotel';
import GlobalStyle from '@/utils/globalstyle';
import { colors } from '@/utils/colors';
import 'dayjs/locale/fr';
import locale from 'antd/lib/locale/fr_FR';
import { AppProvider } from './AppContext';

const defaultData = {
	colorPrimary: colors.primaryLight,
	colorLink: colors.primaryLight,
	colorLinkHover: colors.primaryDark
};

const App = () => {

	const [data] = useState(defaultData);

    const [backgroundImage, setBackgroundImage] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [distType, setDistType] = useState('');
    const [form] = Form.useForm();

    const handleValueChange = useCallback((newValue) => {
        form.setFieldsValue({ city: newValue });
    }, [form]);

    const handleSelectDestination = useCallback((data) => {
        form.submit();
        setTitle(data.city || data.country);
        setDescription(data.description);
        setBackgroundImage(data.image);
        handleValueChange(data.city || data.country);
        setDistType(data.type);	
    }, [handleValueChange, setTitle, setDescription, setBackgroundImage, setDistType, form]);

	return (
		<ConfigProvider
			theme={{ 
				token: { 
					colorPrimary: data.colorPrimary, 
					colorLink: data.colorLink,
					colorLinkHover: data.colorLinkHover 
				} 
			}}
			locale={locale}
		>
			<GlobalStyle />
			<AppProvider>
				<Router>
					<Layout>
						<Switch>
							<Route exact path="/" render={(props) => 
								<HomePage
									handleSelectDestination={(data) => handleSelectDestination(data)}
									isHomePage
									backgroundImage={backgroundImage} 
									title={title}
									setTitle={setTitle}
									description={description} 
									setDistType={setDistType}
									distType={distType}
									form={form}
									{...props} />
								} 
							/>
							<Route exact path="/search" render={(props) => 
								<HomePage
									handleSelectDestination={(data) => handleSelectDestination(data)}
									backgroundImage={backgroundImage} 
									title={title}
									setTitle={setTitle}
									isSearchPage
									description={description} 
									setDistType={setDistType}
									distType={distType}
									form={form}
									{...props} />
								} 
							/>
							<Route exact path="/hotels-favoris" render={(props) => 
								<Favourites
									handleSelectDestination={(data) => handleSelectDestination(data)}
									backgroundImage={backgroundImage} 
									title={title}
									setTitle={setTitle}
									description={description} 
									setDistType={setDistType}
									distType={distType}
									form={form}
									{...props} />
								} 
							/>
							<Route exact path="/mentions" render={(props) => 
								<Mentions
									handleSelectDestination={(data) => handleSelectDestination(data)}
									backgroundImage={backgroundImage} 
									title={title}
									setTitle={setTitle}
									description={description} 
									setDistType={setDistType}
									distType={distType}
									form={form}
									{...props} />
								} 
							/>
							<Route exact path="/cgu" render={(props) => 
								<TermsPage
									handleSelectDestination={(data) => handleSelectDestination(data)}
									backgroundImage={backgroundImage} 
									title={title}
									setTitle={setTitle}
									description={description} 
									setDistType={setDistType}
									distType={distType}
									form={form}
									{...props} />
								} 
							/>
							<Route exact path="/protect-data" render={(props) => 
								<DataProtectionPage
									handleSelectDestination={(data) => handleSelectDestination(data)}
									backgroundImage={backgroundImage} 
									title={title}
									setTitle={setTitle}
									description={description} 
									setDistType={setDistType}
									distType={distType}
									form={form}
									{...props} />
								} 
							/>
							{/* <Route path="/hotels/:id" component={HotelPage} /> */}
						</Switch>
					</Layout>
				</Router>
			</AppProvider>
		</ConfigProvider>
	);
};

export default App;
