import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Skeleton, Button, Row, Typography, notification } from 'antd';
import ImageGallery from 'react-image-gallery';
import { StyledGalleryPhotos } from "@/utils/styles";
import 'react-image-gallery/styles/css/image-gallery.css';
import { BiRightArrowAlt, BiLeftArrowAlt } from 'react-icons/bi';
import { AiFillStar } from 'react-icons/ai';
import { colors } from '@/utils/colors';
import devConfig from '@/config/dev';
import prodConfig from '@/config/prod';

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

const { Title } = Typography;

export const ModalHotelPhotos = ({ _hotel, title, isOpen, closeModal, href, hotelStars }) => {
    const [hotelData, setHotelData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentHotelId, setCurrentHotelId] = useState(null);
    const [loading, setIsLoading] = useState(true);
    const [stars, setStars] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        if (_hotel) {
            setCurrentHotelId(_hotel);
            const fetchHotelData = async () => {
                try {
                    const photos = await axios.get(`${config.API_URL}/hotels/photos`, ({
                        params: {
                            hotelId: _hotel
                        }
                    }));
                    setHotelData(photos?.data);
                } catch (error) {
                    console.error(error);
                    notification.error({
                        message: "Erreur de recherche d'hôtels",
                        description: "Une erreur s'est produite lors de la recherche d'hôtels. Veuillez réessayer plus tard.",
                        placement: "bottomLeft"
                    });
                }
            };
            fetchHotelData();
            setTimeout(async () => {
                setIsLoading(false);
            }, 1000);
        }
    }, [_hotel]);
    
    useEffect(() => {
        if(hotelStars && hotelStars > 0) {
            setStars(Array(hotelStars).fill().map((_, index) => <AiFillStar style={{fontSize: 15}} color={colors.gold} key={index} />));
        }
    }, [hotelStars]);
    
    if (!_hotel) {
        return null;
    }

    const images = hotelData.map(photo => ({
        original: photo.url_1440,
        thumbnail: photo.url_square60
    }));

    const handleModalClose = () => {
        if (currentHotelId !== null && _hotel && currentHotelId === _hotel) {
            closeModal();
        } else {
            setHotelData([]);
            setCurrentIndex(0);
            closeModal();
        }
    };

    const handleImageClick = index => setCurrentIndex(index);

    const MyCustomLeftNav = ({ onClick }) => (
        <Button 
            shape="circle" 
            className='gallery-nav left' 
            onClick={onClick}
            size="large" 
            icon={<BiLeftArrowAlt />}
        />
    );

    const MyCustomRightNav = ({ onClick }) => (
        <Button 
            shape="circle"
            icon={<BiRightArrowAlt />} 
            className='gallery-nav right' 
            onClick={onClick}
            size="large" 
        />
    );

    return (
        <Modal
            centered
            width={"95%"}
            title={
                <Row align="middle" justify="center" gutter={[16, 16]} wrap={false} style={{marginRight: 20}}>
                    <Title style={{marginBottom: 0}} ellipsis level={4}>
                        {hotelStars > 0 && stars} 
                        {title}
                    </Title>
                    <Button type="primary" style={{marginLeft: 10}} href={href} target="_blank">
                        Réserver
                    </Button>
                </Row>
            }
            open={isOpen}
            footer={null}
            onCancel={handleModalClose}
        >
            <StyledGalleryPhotos className="wrapper">
                {loading && (
                    <>
                        <div className="container">
                            <Skeleton.Image active />
                        </div>
                        <div className="thumbnail-skeleton">
                            {Array(6).fill().map((_, index) => (
                                <Skeleton.Image key={index} active />
                            ))}
                        </div>
                    </>
                )}
                {!loading && (
                    <ImageGallery
                        items={images}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        thumbnailPosition={'bottom'}
                        startIndex={currentIndex}
                        lazyLoad
                        onClickThumbnail={handleImageClick}
                        renderLeftNav={(onClick) => <MyCustomLeftNav onClick={onClick} />}
                        renderRightNav={(onClick) => <MyCustomRightNav onClick={onClick} />}
                    />
                )}
            </StyledGalleryPhotos>
        </Modal>
    );
};
