import React, { useEffect } from 'react';
import { HotelSearchForm } from '@/components/search';
import { Footer } from "@/components/footer";
import { Header } from "@/components/header";
import { Typography } from 'antd';
import { StyledContainer } from '@/utils/styles';

const { Title, Paragraph } = Typography;


export const TermsPage = ({ 
    isHomePage, 
    backgroundImage, 
    title, 
    description, 
    setDistType, 
    distType, 
    form, 
    handleSelectDestination,
    setTitle
}) => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);    

    return (
        <>
            <Header
                isHomePage={isHomePage}
                srcBg={backgroundImage}
                title={title}
                description={description}
            />
            <HotelSearchForm 
                isHomePage={isHomePage}
                handleSelectDestination={handleSelectDestination}
                form={form}
                setTitle={setTitle}
                setDistType={setDistType}
                distType={distType}
            />
            
            <StyledContainer className="container">
                <div style={{ maxWidth: 800, margin: '0 auto' }}>
                    <Title style={{ textAlign: 'center', marginBottom: 50 }}>Conditions générales d'utilisation</Title>
                    <Paragraph>
                        En utilisant notre application web, vous acceptez les présentes conditions générales d'utilisation. Si vous n'êtes
                        pas d'accord avec ces conditions, veuillez ne pas utiliser notre application.
                    </Paragraph>
                    <Paragraph>
                        Nous nous réservons le droit de modifier ces conditions générales d'utilisation à tout moment, sans préavis. Votre
                        utilisation continue de notre application après de telles modifications constitue votre acceptation de ces
                        modifications.
                    </Paragraph>
                    <Paragraph>
                        Vous êtes seul responsable de votre utilisation de notre application, et vous vous engagez à ne pas l'utiliser à des
                        fins illégales ou frauduleuses.
                    </Paragraph>
                    <Paragraph>
                        Nous ne pouvons garantir l'exactitude des informations fournies sur notre application, y compris les informations
                        sur les prix des hôtels. Nous nous efforçons de maintenir notre application à jour, mais nous ne pouvons pas garantir
                        que les informations présentées sont exactes ou à jour à tout moment.
                    </Paragraph>
                    <Paragraph>
                        Nous ne sommes pas responsables des dommages directs, indirects, spéciaux ou consécutifs découlant de votre utilisation
                        de notre application, y compris, mais sans s'y limiter, les dommages pour perte de profits, perte de données ou
                        d'interruption d'activité.
                    </Paragraph>
                    <Paragraph>
                        Notre application peut contenir des liens vers des sites tiers. Nous ne sommes pas responsables du contenu de ces sites
                        tiers ou de leur utilisation de vos données personnelles. Nous vous encourageons à lire attentivement les conditions
                        générales d'utilisation et la politique de confidentialité de chaque site que vous visitez.
                    </Paragraph>
                    <Paragraph>
                        En utilisant notre application, vous acceptez que nous recueillions et utilisions vos données personnelles conformément
                        à notre politique de confidentialité.
                    </Paragraph>
                </div>
            </StyledContainer>
            <Footer
                onClick={(data) => handleSelectDestination(data)}
            />

        </>
    );
};

